/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable radix */
function shade(color, percent) {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length == 1 ? `0${R.toString(16)}` : R.toString(16);
  const GG = G.toString(16).length == 1 ? `0${G.toString(16)}` : G.toString(16);
  const BB = B.toString(16).length == 1 ? `0${B.toString(16)}` : B.toString(16);

  return `#${RR}${GG}${BB}`;
}


function loadImage(url) {
  return new Promise(((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.onload = resolve.bind(null, image);
    image.onerror = reject;
    image.src = url;
  }));
}

function getImageData(image) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  canvas.width = image.width;
  canvas.height = image.height;
  context.drawImage(image, 0, 0);
  return context.getImageData(0, 0, image.width, image.height);
}

// http://www.html5canvastutorials.com/advanced/html5-canvas-grayscale-image-colors-tutorial/
function grayscaleImageData(imageData) {
  const {data} = imageData;
  for (let i = 0; i < data.length; i += 4) {
    const brightness = 0.34 * data[i] + 0.5 * data[i + 1] + 0.16 * data[i + 2];
    data[i] = brightness;
    data[i + 1] = brightness;
    data[i + 2] = brightness;
  }
  return imageData;
}

function renderImageData(context, imageData) {
  context.canvas.width = imageData.width;
  context.canvas.height = imageData.height;
  context.putImageData(imageData, 0, 0);
  return imageData;
}

function grayscaleImage(url, context, grayscale = true) {
  loadImage(url)
  .then((image) => getImageData(image))
  .then((imageData) => grayscale ? grayscaleImageData(imageData) : imageData)
  .then((imageData) => renderImageData(context, imageData));
}

export default {
  shade,
  grayscaleImage,
};