import React from 'react';
import QueueAnim from 'rc-queue-anim';
import Layout from 'antd/es/layout';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import PropTypes from 'prop-types';

import Styles from './styles';

const LoginLayout = ({ left, right }) => (
  <Layout>
    <Row style={Styles.row}>
      <Col
        lg={12}
        sm={24}
        md={12}
        className="double-bloc left small-on-med-and-down"
      >
        <QueueAnim duration={4000} type="top" style={Styles.blocs}>
          {left}
        </QueueAnim>
      </Col>
      <Col lg={12} sm={24} md={24} style={Styles.blocs}>
        <QueueAnim
          type="bottom"
          delay={1000}
          duration={2000}
          style={Styles.blocs}
        >
          {right}
        </QueueAnim>
      </Col>
    </Row>
  </Layout>
);

LoginLayout.propTypes = {
  left: PropTypes.element.isRequired,
  right: PropTypes.element.isRequired,
};

export default LoginLayout;
