/* eslint-disable import/prefer-default-export */
import update from 'immutability-helper';

export const setAndGetNewObject = (obj, keys, values) => {
  if (
    !keys.length ||
    !values.length ||
    keys.length === 0 ||
    values.length === 0
  ) {
    throw new Error('Keys and values MUST be set');
  }

  if (keys.length !== values.length) {
    throw new Error('Keys and values MUST be same length');
  }
  const datas = {};
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < keys.length; i++) {
    datas[keys[i]] = { $set: values[i] };
  }

  return update(obj, datas);
};

export const transformSectorValues = (profiles) => (profiles || []).map(p => ({...p, Secteur: (p.Secteur || '') === '' ? [] : (p.Secteur).split(',')}))
