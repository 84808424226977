// import moment from 'moment';
import update from 'immutability-helper';

const listData = [];
/* for (let i = 0; i < 23; i += 1) {
  listData.push({
    href: 'http://ant.design',
    title: `My Event ${i}`,
    avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
    description: 'Gjøvik',
    content:
            'Vi kommer tilbake med dato her,  men vi skal arrangere en konkurranse på '
            + 'Gjøvik i samarbeid med flere innovasjonsaktører i mjøsregionen.',
    datetime: moment(),
  });
} */

export default class EventsModel {
  constructor() {
    this.events = listData;
    this.loading = false;
  }

  toggleLoading() {
    return update(this, {
      loading: { $set: !this.loading },
    });
  }

  setEvents(events) {
    return update(this, {
      events: { $set: events },
    });
  }
}
