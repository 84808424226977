/* eslint-disable react/no-array-index-key */
import React from 'react';
import Button from 'antd/es/button';
import * as showdown from 'showdown';
import TagBlog from '../TagBlog/TagBlog';

const converter = new showdown.Converter();
converter.setOption('simplifiedAutoLink', true);
converter.setOption('simpleLineBreaks', true);
converter.setOption('smoothLivePreview', true);
converter.setOption('openLinksInNewWindow', true);
converter.setOption('strikethrough', true);
const concatUrl = (fragments) =>
  fragments.map((fr) => fr.replace(/^\/|\/$/g, '')).join('/');

// eslint-disable-next-line react/prop-types
const Content = ({ currentElem }) =>
  currentElem && currentElem.Content ? (
    // eslint-disable-next-line max-len
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: converter.makeHtml(currentElem.Content),
        }}
      />
      {currentElem.attachments && currentElem.attachments.length > 0 && (
        <div
          className="content-footer"
          style={{ borderTop: '1px solid #3c3c3c30', padding: '15px 0' }}
        >
          {currentElem.attachments.map((attachment, index) => (
            <Button
              type="link"
              icon="file"
              href={concatUrl([
                process.env.REACT_APP_BACKEND_URL,
                attachment.url,
              ])}
              target="_blank"
              key={`attachme-${index}`}
            >
              {attachment.name}
            </Button>
          ))}
        </div>
      )}
      <div className="tag-wrapper-inline">
        <TagBlog style={{margin: '1%'}} model={currentElem}/>
      </div>
    </>
  ) : (
    <div>-</div>
  );

export default Content;
