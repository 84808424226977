import React from 'react';
import QueueAnim from 'rc-queue-anim';

import LayoutLogin from '../../components/Layouts/Login';
import LogoFullScreenBlock from '../../components/LogoFullScreenBlock';
import ResettFullScreenBlock from '../../components/ResettFullScreenBlock';
import LogoTagline from '../../assets/logo-tagline.png';

const Resett = () => (
  <QueueAnim>
    <LayoutLogin
      left={<LogoFullScreenBlock key={1} logo={LogoTagline} />}
      right={<ResettFullScreenBlock key={2} />}
    />
  </QueueAnim>
);

export default Resett;
