import React from 'react';
import Spin from 'antd/es/spin';
import Ficon from '../../assets/favicon.png';

const loadingIndicator = () => (
  <div>
    <span>
      <img
        src={Ficon}
        alt="funinvest-logo"
        style={{
          width: '1.5em',
          height: '1.5em',
        }}
        className="fe-pulse"
      />
    </span>
  </div>
);

const PulseLoader = () => (
  <Spin
    size="large"
    indicator={loadingIndicator()}
    className="spin-pulse"
  />
);

export default PulseLoader;
