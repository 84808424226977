import notification from 'antd/es/notification';

notification.config({
  placement: 'bottomRight',
});

export const notifyLoginSuccess = () => {
  notification.success({
    message: 'Innlogging velykket',
  });
};

export const notifyLoginFailure = (error) => {
  notification.error({
    message: 'Login failed',
    description: error,
  });
};

export const notifyUpdatePasswordSuccess = (withLogout = true) => {
  const message = withLogout
    ? 'Password has been updated successfully ! You will be logged out in few seconds...'
    : 'Password has been updated successfully !';
  notification.success({
    message,
  });
};

export const notifyUpdatePasswordFailure = (error) => {
  notification.error({
    message: 'Password update failed',
    description: error,
  });
};

export const notifySuccess = (message) => {
  notification.success({
    message,
  });
};

export const notifyFailure = (title, desc) => {
  notification.error({
    message: title,
    description: desc,
  });
};

export const notifyInfo = (message) => {
  notification.info({
    message,
  });
};
