// eslint-disable-next-line import/no-named-as-default
import App from './App';
import Blog from './Blog';
import Deltagere from './Deltagere';
import Events from './Events';
import Investments from './Investments';
import Notifications from './Notifications';

export default {
  App,
  Investments,
  Blog,
  Deltagere,
  Events,
  Notifications,
};
