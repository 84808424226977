import React from 'react';
import Button from 'antd/es/button';
import Icon from 'antd/es/icon';
import PropTypes from 'prop-types';

import { goTo } from '../../utils/Navigation';

const ModalNavigation = ({ userToShow, profiles, setCurrentUser, show }) =>
  show ? (
    <span className="navigation-buttons">
      <Button
        className="navigation-buttons-left"
        type="primary"
        onClick={() => {
          goTo('prev', userToShow, profiles, setCurrentUser);
        }}
      >
        <Icon type="left" />
      </Button>
      <Button
        className="navigation-buttons-right"
        type="primary"
        onClick={() => {
          goTo('next', userToShow, profiles, setCurrentUser);
        }}
      >
        <Icon type="right" />
      </Button>
    </span>
  ) : null;

ModalNavigation.defaultProps = {
  show: true,
};

ModalNavigation.propTypes = {
  userToShow: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setCurrentUser: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

export default ModalNavigation;
