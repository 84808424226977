/* eslint-disable no-constant-condition */
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Upload from 'antd/es/upload';
import Button from 'antd/es/button';
import Select from 'antd/es/select';
import Divider from 'antd/es/divider';

import { PropTypes } from 'prop-types';
import React from 'react';
import _get from 'lodash.get';
import { connect } from 'react-redux';
import _omit from 'lodash.omit';

import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateFromMarkdown } from 'draft-js-import-markdown';
import { stateToMarkdown } from 'draft-js-export-markdown';

import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  updateUserProfile,
  updateUserProfileWithFile,
} from '../../../redux/modules/Deltagere';

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 },
};

class DetailsForm extends React.Component {
  constructor(props) {
    super(props);

    this.onSave = this.onSave.bind(this);
    const desc = _get(props, 'profile.Desc', null);
    const origImageUrl = _get(props, 'profile.Photo.url', '');
    this.state = {
      editorState: !desc
        ? EditorState.createEmpty()
        : EditorState.createWithContent(stateFromMarkdown(desc)),
      fileEntry: null,
      imageUrl:
        origImageUrl !== ''
          ? `${process.env.REACT_APP_BACKEND_URL}${origImageUrl}`
          : null,
    };
    this.onEditorStateChange = this.onEditorStateChange.bind(this);

    this.defaultSektorOpts = [];
  }

  onEditorStateChange(editorState) {
    this.setState({ editorState });
  }

  onSave(e) {
    e.preventDefault();
    const {
      form,
      toggleModal,
      saveUser,
      profile,
      saveUserWithFile,
    } = this.props;
    const { editorState, fileEntry } = this.state;

    form.validateFields((err, values) => {
      if (!err) {
        const valsToSend = _omit(values, 'Photo');
        valsToSend.Links = valsToSend.Links ? valsToSend.Links.join(';') : '';
        valsToSend.Desc = editorState
        ? stateToMarkdown(editorState.getCurrentContent())
        : '';
        valsToSend.Secteur = _get(values, 'Secteur', []).join(',');
        // handle file upload
        if (fileEntry) {
          saveUserWithFile(profile.id, fileEntry, valsToSend).finally(() => {
            if(typeof toggleModal === 'function') {
              toggleModal();
            }
          });
        } else {
          saveUser(profile.id, valsToSend).finally(() => {
            if(typeof toggleModal === 'function') {
              toggleModal();
            }
          });
        }
      }
    });
  }

  render() {
    const { profile, form, isUpdatingUser } = this.props;
    const { editorState, imageUrl } = this.state;

    const uploadButton = (
      <div>
        <Icon type={false ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <Form {...formItemLayout} onSubmit={this.onSave} className="details-form">
        <Form.Item label="Navn">
          {form.getFieldDecorator('username', {
            initialValue: profile.username || '',
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              size="large"
              disabled
            />
          )}
        </Form.Item>
        <Form.Item label="Epost">
          {form.getFieldDecorator('email', {
            rules: [
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
            ],
            initialValue: profile.email || '',
          })(
            <Input
              prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
              size="large"
              disabled
            />
          )}
        </Form.Item>
        <Form.Item label="Bilde">
          {form.getFieldDecorator('photo')(
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              method="POST"
              action={`${process.env.REACT_APP_BACKEND_URL}upload`}
              beforeUpload={(file) => {
                this.setState({
                  fileEntry: file,
                  imageUrl: URL.createObjectURL(file),
                });
                return false;
              }}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
              ) : (
                uploadButton
              )}
            </Upload>
          )}
        </Form.Item>
        <Form.Item label="Om meg">
          {form.getFieldDecorator('Desc')(
            <Editor
              editorState={editorState}
              toolbar={{
                options: ['inline', 'blockType', 'list', 'textAlign', 'link'],
              }}
              onEditorStateChange={this.onEditorStateChange}
            />
          )}
        </Form.Item>
        <Form.Item label="Telefon">
          {form.getFieldDecorator('Phone', {
            initialValue: profile.Phone || '',
          })(
            <Input
              prefix={
                <Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />
              }
              size="large"
            />
          )}
        </Form.Item>
        <Form.Item label="Sektor">
          {form.getFieldDecorator('Secteur', {
            initialValue: typeof profile.Secteur === 'string' ? profile.Secteur.split(',') : profile.Secteur,
          })(
            <Select
              className="sektor-select"
              mode="tags"
              size="large"
              // placeholder="Please select or add your sector of activities"
              style={{ width: '100%' }}
            >
              {this.defaultSektorOpts.map((m) => (
                <Option key={`$-sek-${m}`} value={m}>{m}</Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Divider dashed />

        <Form.Item label="Linker">
          {form.getFieldDecorator('Links', {
            initialValue: (profile.Links || '')
              .split(';')
              .filter((val) => !!val),
          })(
            <Select
              mode="tags"
              dropdownStyle={{ display: 'none' }}
              tokenSeparators={[';', ',']}
            />
          )}
        </Form.Item>
        <Form.Item label="Twitter">
          {form.getFieldDecorator('Twitter', {
            initialValue: profile.Twitter || '',
          })(
            <Input
              prefix={
                <Icon type="twitter" style={{ color: 'rgba(0,0,0,.25)' }} />
              }
              size="large"
            />
          )}
        </Form.Item>
        <Form.Item label="Instagram">
          {form.getFieldDecorator('Instagram', {
            initialValue: profile.Instagram || '',
          })(
            <Input
              prefix={
                <Icon type="instagram" style={{ color: 'rgba(0,0,0,.25)' }} />
              }
              size="large"
            />
          )}
        </Form.Item>
        <Form.Item label="Facebook">
          {form.getFieldDecorator('Facebook', {
            initialValue: profile.Facebook || '',
          })(
            <Input
              prefix={
                <Icon type="facebook" style={{ color: 'rgba(0,0,0,.25)' }} />
              }
              size="large"
            />
          )}
        </Form.Item>
        <Form.Item label="Linkedin">
          {form.getFieldDecorator('Linkedin', {
            initialValue: profile.Linkedin || '',
          })(
            <Input
              prefix={
                <Icon type="linkedin" style={{ color: 'rgba(0,0,0,.25)' }} />
              }
              size="large"
            />
          )}
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          style={{ float: 'right' }}
          loading={isUpdatingUser}
        >
          Save
        </Button>
      </Form>
    );
  }
}

DetailsForm.propTypes = {
  form: PropTypes.shape({
    getFieldsValue: PropTypes.func,
    getFieldDecorator: PropTypes.func,
  }).isRequired,
  profile: PropTypes.shape({}).isRequired,
  toggleModal: PropTypes.func.isRequired,
  saveUser: PropTypes.func.isRequired,
  isUpdatingUser: PropTypes.bool.isRequired,
  saveUserWithFile: PropTypes.func.isRequired,
};

const mapStateToProps = ({ Deltagere }) => ({
  isUpdatingUser: Deltagere.isUpdatingUser,
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (userId, datas) => dispatch(updateUserProfile(userId, datas)),
  saveUserWithFile: (userId, file, datas) =>
    dispatch(updateUserProfileWithFile(userId, file, datas)),
});

const TheDetailForm = Form.create({ name: 'user_update' })(DetailsForm);
export default connect(mapStateToProps, mapDispatchToProps)(TheDetailForm);
