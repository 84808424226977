import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

import {
  toggleMenu as reduxToggleMenu,
  logout as reduxLogout,
  toggleReportModal,
  toggleFeatureModal,
  createTicket,
} from '../../redux/modules/App';
import OnlyAuthorizedPage from '../../hoc/OnlyAuthorizedPage';
import Home from '../../components/Layouts/Home';
import { openModal } from '../../redux/modules/Blog';

const HocHome = (props) => {
  ReactGA.initialize(process.env.REACT_APP_GA, []);
  return <Home {...props}/>
}

const mapStateToProps = (state) => ({
  menuFolded: state.App.menuFolded,
  reportModalIsOpen: state.App.reportModalShown,
  featureModalIsOpen: state.App.featureModalShown,
  sendingTicket: state.App.isCreatingTicket,
  loggedUser: state.App.loggedUserObj,
});
const mapDispatchToProps = (dispatch) => ({
  toggleMenu: () => {
    dispatch(reduxToggleMenu());
  },
  logout: () => {
    dispatch(reduxLogout());
  },
  _openModal: () => {
    dispatch(openModal());
  },
  _toggleReportModal: () => dispatch(toggleReportModal()),
  _toggleFeatureModal: () => dispatch(toggleFeatureModal()),
  _createTicket: ({ ...args }) => dispatch(createTicket({ ...args })),
});

export default OnlyAuthorizedPage(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(HocHome))
);
