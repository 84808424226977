import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { connect } from 'react-redux';

import LayoutLogin from '../../components/Layouts/Login';
import LogoFullScreenBlock from '../../components/LogoFullScreenBlock';
import LoginFormBlock from '../../components/LoginFormBlock';
import Logo from '../../assets/logo.png';
import { requestLogin as requestLoginAction } from '../../redux/modules/App';
import redirectToHomeIfLoggedIn from '../../hoc/RedirectToHomeIfLogged';

const mapStateToProps = (state) => ({
  isRequestingLogin: state.App.isRequestingLogin,
  loginRequestSuccessed: state.App.loginRequestSuccess,
  userIsLogged: state.App.loggedIn,
  nameOfUserLogged: state.App.loggedUser,
});
const mapDispatchToProps = (dispatch) => ({
  requestLogin: (username, password, accessToken) => {
    dispatch(requestLoginAction(username, password, accessToken));
  },
});

const LoginFormBlockWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginFormBlock);

const Login = () => (
  <QueueAnim>
    <LayoutLogin
      left={<LogoFullScreenBlock key={1} logo={Logo} />}
      right={<LoginFormBlockWithRedux key={2} />}
    />
  </QueueAnim>
);

export default redirectToHomeIfLoggedIn(Login);
