/* eslint-disable import/prefer-default-export */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { menus } from '../constants/menus';

export const wrapWithFirstMenuLink = (component, _class = 'logo-f-desktop') => (
  <NavLink to={menus[0].to} className={_class || ''}>
    {component}
  </NavLink>
);
