import React, { useEffect, useRef } from 'react';
import Card from 'antd/es/card';
import Collapse from 'antd/es/collapse';
import Icon from 'antd/es/icon';

import Carousel from 'antd/es/carousel';
import NoImage from '../../assets/no-image.png';

import colors from '../../utils/colors';
import TagBlog from '../TagBlog/TagBlog';

const { Panel } = Collapse;

const CarouselImg = ({ img, noGrayscaleImage }) => {
  const canvasRef = useRef();

  useEffect(() => {
    if (canvasRef) {
      const canvasElem = canvasRef.current;
      const context = canvasElem.getContext('2d');
      colors.grayscaleImage(img, context, !noGrayscaleImage && img !== NoImage);
    }
  }, []);

  return <canvas ref={canvasRef} />;
};

const HestBadge = ({ hest }) => {
  const images =
    (hest.coverPhoto || []).length < 1
      ? [{ name: 'no-img', id: Math.random(2) * 10, url: NoImage }]
      : hest.coverPhoto.map((img) => ({
          ...img,
          url: `${process.env.REACT_APP_BACKEND_URL}${img.url}`,
        }));

  const active = hest.status === 'active';

  return (
    <div style={{ maxWidth: 425 }}>
      <Card
        className={`hest-card ${!active ? 'grayscale' : ''}`}
        cover={
          <Carousel arrows dots={false}>
            {images.map((img) => (
              <CarouselImg
                key={`$.img.grey.${img.id}`}
                img={img.url}
                noGrayscaleImage={active}
              />
            ))}
          </Carousel>
        }
      >
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <Icon type="caret-right" rotate={isActive ? 90 : 0} />
          )}
          expandIconPosition="right"
          defaultActiveKey={active ? ['1'] : ['0']}
        >
          <Panel
            key={1}
            header={
              <div className="tag-title">
                <h2>{hest.name}</h2>
              </div>
            }
            style={{ border: 0 }}
          >
            {hest.description}
            <TagBlog model={hest} />
          </Panel>
        </Collapse>
      </Card>
    </div>
  );
};

export default HestBadge;
