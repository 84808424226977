import React from 'react';
import Typography from 'antd/es/typography';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { updatePasswordFromForgot } from '../../api/Methods';
import { notifySuccess, notifyFailure } from '../../utils/notifications';

const { Title } = Typography;

const erroredStyle = { paddingTop: '35%', fontSize: 'bold', color: 'red' };

class PasswordReset extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      invalidToken: false,
      isRequestingPasswordUpdate: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const token = location.search.split('?code=')[1];
    if (!token) {
      this.setState({ loading: false, invalidToken: true });
    }

    this.setState({ loading: false });
  }

  submit(e, form, action, token) {
    e.preventDefault();
    this.setState({ isRequestingPasswordUpdate: true });

    form.validateFields((err, values) => {
      if (!err) {
        action(token, values.password)
          // eslint-disable-next-line no-unused-vars
          .then(({ data }) => {
            notifySuccess('Password updated !');
            setTimeout(() => {
              window.location.replace('/');
            }, 200);
          })
          .catch((err2) => {
            if (!err2.response.data.message) {
              notifyFailure('Error', err2.message);
            } else {
              notifyFailure(
                'Error',
                err2.response.data.message[0].messages[0].message
              );
            }
          })
          .finally(() => {
            this.setState({ isRequestingPasswordUpdate: false });
          });
      } else {
        this.setState({ isRequestingPasswordUpdate: false });
      }
    });
  }

  renderContent() {
    const { invalidToken, isRequestingPasswordUpdate } = this.state;
    const { form } = this.props;
    const { location } = this.props;
    const token = location.search.split('?code=')[1];

    return invalidToken ? (
      <p align="center" style={erroredStyle}>
        Token is invalid
      </p>
    ) : (
      <div
        style={{
          textAlign: 'center',
          position: 'relative',
          top: '32%',
        }}
        className="login-form-responsive-padding"
      >
        <Title style={{ fontSize: '45px', fontWeight: '300' }}>
          Sett nytt passord
        </Title>
        <br />
        <br />
        <Form
          style={{ margin: 'auto' }}
          onSubmit={(e) => {
            this.submit(e, form, updatePasswordFromForgot, token);
          }}
        >
          <Form.Item>
            {form.getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: 'You must enter a password',
                },
              ],
            })(<Input placeholder="Passord" size="large" type="password" />)}
          </Form.Item>
          <Form.Item>
            {form.getFieldDecorator('password2', {
              rules: [
                {
                  required: true,
                  message: 'Password should match',
                  validator: (rule, value, callback) => {
                    const currentPass = form.getFieldValue('password');
                    if (currentPass !== value) {
                      callback(new Error('Passwords must match'));
                    } else {
                      callback();
                    }
                  },
                },
              ],
            })(
              <Input
                placeholder="Gjenta passord"
                size="large"
                type="password"
              />
            )}
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ width: '100%' }}
              size="large"
              loading={isRequestingPasswordUpdate}
            >
              SEND
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }

  render() {
    const { loading } = this.state;

    return loading ? (
      <p align="center" style={erroredStyle}>
        Verifying token...
      </p>
    ) : (
      this.renderContent()
    );
  }
}

PasswordReset.propTypes = {
  form: PropTypes.shape({
    validateFields: PropTypes.func,
    getFieldDecorator: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
  // _requestPassUpdate: PropTypes.func.isRequired,
};

export default Form.create({ name: 'form' })(withRouter(PasswordReset));
