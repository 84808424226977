/* eslint-disable no-nested-ternary */
import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import Menu from "antd/es/menu";
import Tooltip from "antd/es/tooltip";
import Icon from "antd/es/icon";
import PropTypes from "prop-types";
import find from "lodash.find";

import { menus /* INVESTERINGER_SUB_MENUS */ } from "../../constants/menus";

// const { SubMenu } = Menu;
const _menus = [...menus];

const wrapInToolTip = (menuName, comp) => (
  <Tooltip
    placement="right"
    title={menuName}
    overlayStyle={{ marginLeft: "3em", zIndex: "9999999999" }}
    align={{ offset: [30, 0] }}
    overlayClassName="special-tip"
  >
    {comp}
  </Tooltip>
);

export const wrapMenuItemWithLink = (menu, menuFolded, noLabel) => {
  let icon = (
    <Icon
      type={menu.icon}
      theme="filled"
      style={{ color: "white" }}
      className={`${menu.icon}-class`}
    />
  );
  if (menu.img) {
    icon = <img src={menu.img} alt="menu" className="menu-image-icon" />;
  }

  if (menu.component) {
    icon = menu.component;
  }

  return (
    <NavLink key={`nav-with-link-${menu.id}`} to={menu.to}>
      {icon}
      {!menuFolded ? (noLabel ? "" : menu.name) : null}
    </NavLink>
  );
};

// eslint-disable-next-line no-unused-vars
const renderMenus = (menuFolded, location = "", noLabel) =>
  _menus
    /* .filter((menu) => {
      // do not show the home button on desktops
      if (menu.name === 'Home' && window.innerWidth > 768) {
        return false;
      }

      return true;
    }) */
    .map((menu) => {
      // const { id } = menu;
      const regularMenu = (
        <Menu.Item
          key={menu.id}
          className="investeringer-sub-menu"
          onItemHover={() => false}
          onClick={() => false}
        >
          {menuFolded
            ? wrapInToolTip(
                menu.name,
                wrapMenuItemWithLink(menu, menuFolded, noLabel)
              )
            : wrapMenuItemWithLink(menu, menuFolded, noLabel)}
        </Menu.Item>
      );

      return regularMenu;

      /* let menuToReturn = regularMenu;
    const submenuStyle = {
      backgroundColor: location.pathname === menu.to ? '#e6f7ff' : 'transparent',
    };
    // special submenu for investeringer
    if (id === 3) {
      menuToReturn = (
        <SubMenu key={`wrapper-${menu.id}`} style={submenuStyle} className="submenu-investeringer-class" title={regularMenu}>
          {INVESTERINGER_SUB_MENUS.map(e => (
          // eslint-disable-next-line react/no-array-index-key
            <Menu.Item key={e.id + 100}>
              {wrapMenuItemWithLink(e, menuFolded)}
            </Menu.Item>
          ))}
        </SubMenu>
      );
    }

    return menuToReturn; */
    });

const SidenavMenu = ({
  location,
  menuFolded,
  mode,
  style,
  className,
  noLabel,
}) => {
  const menuObj = find(
    menus,
    (ob) => ob.to === location.pathname
    // && ob.id !== 3
  );

  return (
    <Menu
      className={className}
      style={{ ...style }}
      mode={mode}
      theme="light"
      selectedKeys={menuObj ? [menuObj.id.toString()] : []}
    >
      {renderMenus(menuFolded, location, noLabel)}
    </Menu>
  );
};

SidenavMenu.defaultProps = {
  mode: "vertical",
  style: {},
  noLabel: false,
};

SidenavMenu.propTypes = {
  location: PropTypes.shape({}).isRequired,
  menuFolded: PropTypes.bool.isRequired,
  mode: PropTypes.string,
  style: PropTypes.shape({}),
  noLabel: PropTypes.bool,
};

export default withRouter(SidenavMenu);
