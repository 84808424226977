/* eslint-disable no-case-declarations */
import update from 'immutability-helper';
import sortBy from 'lodash.sortby';
import * as LZString from 'lz-string';
import reverse from 'lodash.reverse';
import { findIndex, uniqBy } from 'lodash';
import { getInvesteringer, getKrypto, getStartups, getHistories } from '../../api/Methods';
import InvestmentHelper from '../../utils/investments';
import errors from '../../utils/errors';

const Compress = require('compress-object');

export const REQUEST_START = 'Investments/request_start';
export const REQUEST_END = 'Investments/request_end';

export const REQUEST_STARTUP_START = 'Investment_request_investment_start';
export const REQUEST_STARTUP_END = 'Investment_request_investment_end';
const SET_INVESTMENTS = 'Investment/set';

export const SET_KRYPTO = 'Investments/update_krypto';

export const REQUEST_HISTORIES_START = 'Investments/request_histories_start';
export const REQUEST_HISTORIES_END = 'Investments/request_histories_end';

const defaultState = {
  loading: false,
  investments: [],
  annet: [],
  startup: [],
  histories: [],
  historyLoading: false,
  historyFetched: false,
};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_START:
    case REQUEST_STARTUP_START:
      return update(state, { loading: { $set: true } });
    
    case REQUEST_HISTORIES_START:
      return update(state, { historyLoading: { $set: true }, historyFetched: { $set: true } });
    case REQUEST_HISTORIES_END:
      return update(state, { 
        historyLoading: { $set: false },
        histories: { $set : action.payload },
      });  

    case SET_INVESTMENTS:
      return update(state, { investments: { $set: action.payload } });

    case REQUEST_END:
      const res = [...action.payload];
      InvestmentHelper.addDisplayAndNormalized(res);

      return update(state, {
        loading: { $set: false },
        investments: { $set: InvestmentHelper.sortInvestmentsByAmount(res) },
      });

    case SET_KRYPTO:
      const keyOfKrypto = findIndex(
        state.investments,
        (k) => k.Name === action.payload.Name
      );
      if (keyOfKrypto < 0) {
        return update(state, {
          investments: { $push: [action.payload] },
        });
      }
      return update(state, {
        [`investments[${keyOfKrypto}]`]: { $set: [action.payload] },
      });

    case REQUEST_STARTUP_END:
      return update(state, {
        [action.key]: { $set: action.payload },
        loading: { $set: false },
      });

    default:
      return state;
  }
};

export default reducer;

export const _fetchInvesteringer = () => async (dispatch) => {
  dispatch({ type: REQUEST_START });
  try {
    const investeringer = await getInvesteringer();
    const kryptos = await getKrypto();
    const combinedInvestments = [...(kryptos.data || []), ...(investeringer.data || [])];
    const res = uniqBy(combinedInvestments, (invest) => invest.id);

    dispatch({
      type: REQUEST_END,
      payload: reverse(sortBy(res || [], 'Amount')),
    });
  } catch (err) {
    errors.handle(err, dispatch);
  }
};

export const _setKrypto = (kryptoData) => ({
  type: SET_KRYPTO,
  payload: kryptoData,
});

export const _setInvestments = (payload) => ({
  type: SET_INVESTMENTS,
  payload,
});

export const fetchSt = (tp) => async (dispatch) => {
  dispatch({
    type: REQUEST_STARTUP_START,
  });

  const startups = await getStartups(tp);

  dispatch({
    type: REQUEST_STARTUP_END,
    payload: startups.data || [],
    key: tp,
  });
};

export const _fetchHistories = (filter) => async (dispatch) => {
  dispatch({ type: REQUEST_HISTORIES_START });
  try {
    const { data } = await getHistories(filter);
    const histories = LZString.decompressFromBase64(data);
    const compressor = new Compress({
      name: '',
      amount: '',
      type: '',
      sektor: '',
      createdAt: ''
    });

    const payload = compressor.deserialize(JSON.parse(histories));

    dispatch({
      type: REQUEST_HISTORIES_END,
      payload
    });
  } catch (err) {
    errors.handle(err, dispatch);
  }
};
