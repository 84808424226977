import concat from 'lodash.concat';
import remove from 'lodash.remove';
import findKey from 'lodash.findkey';
import uniq from 'lodash.uniq';
import update from 'immutability-helper';

import { LOGGED_USER_OBJ } from '../../utils/StorageKeys';
import browserStorage from '../../utils/browserStorage';
import { setAndGetNewObject, transformSectorValues } from '../../utils/objects';

export default class Deltagere {
  constructor() {
    this.profiles = [];
    this.connectedProfile = JSON.parse(
      browserStorage.get(LOGGED_USER_OBJ) || '{}'
    );
    this.connectedProfile.updatable = true;
    this.isLoading = false;
    this.isUpdatingUser = false;
    this.isEditMode = false;
    this.sectorOpts = [];
  }

  arrangeDisplayOrder() {
    // eslint-disable-next-line max-len
    const profiles = remove(
      this.profiles,
      (profile) =>
        !(this.connectedProfile && profile.id === this.connectedProfile.id)
    );
    return setAndGetNewObject(
      this,
      ['profiles'],
      [concat(this.profiles, profiles)]
    );
  }

  toggleLoading() {
    return setAndGetNewObject(this, ['isLoading'], [!this.isLoading]);
  }

  buildSectorOpts() {
    if(this.sectorOpts.length > 0)
    return this;
    
    const opts = [];
    (this.profiles || []).forEach((p) => {
      (p.Secteur|| []).forEach((s) => {
        if(s !== '')
        opts.push(s);
      });
    });
    return update(this, {
      sectorOpts: { $set: uniq(opts) }
    });
  }

  storeUsers(fetchedProfiles) {
    // store the conected profile also
    const _connectedProfile = JSON.parse(
      browserStorage.get(LOGGED_USER_OBJ) || '{}'
    );
    _connectedProfile.updatable = true;

    return setAndGetNewObject(
      this,
      ['profiles', 'connectedProfile'],
      [transformSectorValues(fetchedProfiles), _connectedProfile]
    );
  }

  updateUserStarts() {
    return setAndGetNewObject(this, ['isUpdatingUser'], [true]);
  }

  updateUserStops() {
    return setAndGetNewObject(this, ['isUpdatingUser'], [false]);
  }

  toggleEditMode() {
    return setAndGetNewObject(this, ['isEditMode'], [!this.isEditMode]);
  }

  storeUser(data) {
    let indexOfCurrentUser = findKey(this.profiles, (p) => data.id === p.id);
    indexOfCurrentUser = parseInt(indexOfCurrentUser, 10);
    const user = {...data, Secteur: (data.Secteur || '') === '' ? [] : (data.Secteur).split(',') }
    browserStorage.set(LOGGED_USER_OBJ, user, true);
    return update(this, {
      profiles: {
        $splice: [[indexOfCurrentUser, 1, user]],
      },
      connectedProfile: { $set: user },
    });
  }
}
