import HttpClientWithAuth, {
  HttpClient,
  RawHttpClient,
} from '../utils/httpClient';
import BrowserStorage from '../utils/browserStorage';
import { LOGGED_USER_JWT } from '../utils/StorageKeys';

const HttpClientWithoutAuth = new HttpClient();

export const authorize = (username, password) =>
  HttpClientWithoutAuth.post('auth/local', {
    identifier: username,
    password,
  });

export const authorizeAccessToken = (accessToken) => 
  HttpClientWithoutAuth.get(`auth/facebook/callback/?access_token=${accessToken}`);

// set the jwt token in header
HttpClientWithAuth.setDefaultHeader(
  'Authorization',
  `Bearer ${BrowserStorage.get(LOGGED_USER_JWT, '')}`
);

export const updateUser = (userId, datas) =>
  HttpClientWithAuth.put(`users/${userId}`, datas);

export const updatePassword = (userId, newPassword) =>
  updateUser(userId, {
    password: newPassword,
  });

// Get a list of all users
export const getUsers = () => HttpClientWithAuth.get('users');

export const uploadFile = (formData) =>
  HttpClientWithAuth.post('upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const createBlog = (datas) => HttpClientWithAuth.post('blogs', datas);
export const updateBlog = (datas) =>
  HttpClientWithAuth.put(`blogs/${datas.id}`, datas);
export const listBlogs = () => HttpClientWithAuth.get('blogs');
export const deleteBlog = (id) => HttpClientWithAuth.delete(`blogs/${id}`);

export const verifyCapctha = (token) => {
  const rawClient = new RawHttpClient();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  return rawClient.post(`${backendUrl}captcha/verify`, {
    token,
  });
};

export const sendEmail = ({ ...args }) => {
  const rawClient = new RawHttpClient();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  return rawClient.post(`${backendUrl}sendmail`, { ...args });
};

export const createJiraTicket = ({ title, description, type, epicname }) => {
  if (type === 'epic' && !epicname) {
    throw new Error('Epicname should be set for epic type');
  }

  const datas = {
    title,
    type,
    description,
  };

  if (type === 'epic') {
    datas.epicname = epicname;
  }

  return HttpClientWithAuth.post('jira-tickets/create', { ...datas });
};

export const generateResetToken = (email) => {
  const rawClient = new RawHttpClient();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  return rawClient.post(`${backendUrl}auth/forgot-password`, { email });
};

export const verifyResetToken = (token) => {
  const rawClient = new RawHttpClient();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  return rawClient.get(`${backendUrl}api/password/reset/verify/${token}`);
};

export const updatePasswordFromForgot = (token, newPassword) => {
  const rawClient = new RawHttpClient();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  return rawClient.post(`${backendUrl}auth/reset-password`, {
    code: token,
    password: newPassword,
    passwordConfirmation: newPassword,
  });
};

export const getEvents = () => HttpClientWithAuth.get('events?_sort=Date:DESC');

// export const checkTotalKrypto = () => HttpClientWithAuth.get(`${process.env.REACT_APP_BACKEND_URL}api/krypto`);

// Get krypto amount separately
export const getKrypto = () =>
  HttpClientWithAuth.get(
    `${process.env.REACT_APP_BACKEND_URL}investeringers/kryptos`
  );
export const getInvesteringer = () =>
  HttpClientWithAuth.get(`${process.env.REACT_APP_BACKEND_URL}investeringers`);

export const getStartups = (tp) =>
  HttpClientWithAuth.get(
    `${process.env.REACT_APP_BACKEND_URL}startups?Type=${tp}`
  );

export const getHistories = ({start, end}) => HttpClientWithAuth.get(`${process.env.REACT_APP_BACKEND_URL}histories/file/${start}/${end}`);
export const searchTag = (search) => HttpClientWithAuth.post(`${process.env.REACT_APP_BACKEND_URL}blogs/search`, { search });
  
export const getHests = async () => {
  try {
    const { data } = await HttpClientWithAuth.get(
      `${process.env.REACT_APP_BACKEND_URL}hests?_sort=purchaseDate:DESC`
    );
    return data || [];
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    return [];
  }
};
