import { connect } from 'react-redux';
import Blog from '../../../components/Blog';
import { loadBlogs, countMyVote, toggleDrawer } from '../../../redux/modules/Blog';
import { loadUsers } from '../../../redux/modules/Deltagere';

const mapStateToProps = (state) => ({
  articles: state.Blog.articles,
  isLoading: state.Blog.isLoading,
  openDrawer: state.Blog.openDrawer,
  drawerArticles: state.Blog.drawerArticles,
});

const mapDispatchToProps = (dispatch) => ({
  _listBlogs: () => dispatch(loadBlogs()),
  _vote: (articleId, choiceId, voterId) =>
    dispatch(countMyVote(articleId, choiceId, voterId)),
  fetchUsers: () => dispatch(loadUsers()),
  _toggleDrawer: (value, id) => dispatch(toggleDrawer(value, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
