import { notifyInfo } from './notifications';

const setPageTitle = (title) => {
  document.title = title;
};

const setMetaDesc = (desc) => {
  document.getElementsByTagName('meta').keywords.content = desc;
};

const copyTextToClipboard = (text, dataType = 'Link') => {
  // seems like not working on non https
  // navigator.clipboard.writeText(text);
  const $body = document.getElementsByTagName('body')[0];
  const $tempInput = document.createElement('INPUT');
  $body.appendChild($tempInput);
  $tempInput.setAttribute('value', text);
  $tempInput.select();
  document.execCommand('copy');
  $body.removeChild($tempInput);

  notifyInfo(`Linken er kopiert til utklippstavlen`);
};

const getBaseUrl = () => `${window.location.protocol}//${window.location.host}`;

const setUrlWithoutRedirection = (url) => {
  window.history.pushState('', '', url);
};

const setUrlToBlogPostUrl = (post) => {
  setUrlWithoutRedirection(
    `${getBaseUrl()}/share/blog/post/${post.id || undefined}`
  );
};

const setUrlToRootBlog = () => {
  setUrlWithoutRedirection(`${getBaseUrl()}/home`);
};

const setUrlToGivinPath = (path) => {
  setUrlWithoutRedirection(`${getBaseUrl()}${path}`);
}

export default {
  setPageTitle,
  setMetaDesc,
  copyTextToClipboard,
  getBaseUrl,
  setUrlWithoutRedirection,
  setUrlToBlogPostUrl,
  setUrlToRootBlog,
  setUrlToGivinPath
};
