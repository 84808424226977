import React from 'react';
import QueueAnim from 'rc-queue-anim';

import LayoutLogin from '../../components/Layouts/Login';
import LogoFullScreenBlock from '../../components/LogoFullScreenBlock';
import OmFullScreenBlock from '../../components/OmFullScreenBlock';
import LogoTagline from '../../assets/logo-tagline.png';

const Om = () => (
  <QueueAnim>
    <LayoutLogin
      left={<LogoFullScreenBlock key={1} logo={LogoTagline} />}
      right={<OmFullScreenBlock key={2} />}
    />
  </QueueAnim>
);

export default Om;
