/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import Layout from 'antd/es/layout';
import Row from 'antd/es/row';
import Button from 'antd/es/button';
import Drawer from 'antd/es/drawer';
import QueueAnim from 'rc-queue-anim';
import PropTypes from 'prop-types';

import BlogEntry from './BlogEntry';
import BlogEntryDrawer from './BlogEntryDrawer';
import ListWithModal from '../../hoc/ListWithModal';
import BlogEdit from './BlogEdit';
import PulseLoader from '../PulseLoader/index';
import BlogView, { Article } from './BlogView';
import HtmlHelper from '../../utils/html';

const ButtonClose = <Button icon="up" type="danger" />;

export const BlogWrapped = ({
  closeModalSignal,
  articles,
  _vote,
  _toggleDrawer,
  inDrawer,
  setCloseModalSignal,
}) => (
  <ListWithModal
    className="modal-blog article"
    closeIcon={ButtonClose}
    onCloseModal={() => {
      HtmlHelper.setUrlToRootBlog();
      HtmlHelper.setPageTitle(`Fun Invest Portal`);
    }}
    closeModalSignal={closeModalSignal}
    datas={articles}
    render={(article, openModal) => {
      if (inDrawer) {
        return (
          <BlogEntryDrawer
            key={article.id}
            openModal={openModal}
            art={article}
            _toggleDrawer={_toggleDrawer}
            setCloseModalSignal={setCloseModalSignal}
          />
        );
      }
      return <BlogEntry key={article.id} openModal={openModal} art={article} />;
    }}
    modalContent={(article) => {
      if (!article) {
        return <div />;
      }

      return (
        <Article
          article={article}
          _voteFunc={_vote}
          _toggleDrawer={_toggleDrawer}
        />
      );
    }}
  />
);

const Blog = ({
  _listBlogs,
  articles,
  isLoading,
  _vote,
  openDrawer,
  _toggleDrawer,
  match,
  drawerArticles,
}) => {
  const { id } = match.params;

  const [closeModalSignal, setCloseModalSignal] = useState(0);

  const _setCloseModalSignal = () => {
    setCloseModalSignal(Math.random(2));
  };

  useEffect(() => {
    if (articles.length < 1) {
      _listBlogs();
    }
  }, []);

  // eslint-disable-next-line class-methods-use-this
  const rearrangeBlogsAndSetFeatured = (_articles) => {
    let articlesArranged = _articles.slice(0).reverse();
    // clear all previous "isFeatured" tag as it may be outdated
    articlesArranged = articlesArranged.map((art) => {
      art.isFeatured = false;

      return art;
    });

    // put the ones featured first
    articlesArranged.sort(({ featured: a }, { featured: b }) => {
      if ((a && b) || (!a && !b)) {
        return 0;
      }
      if (a) {
        return -1;
      }

      return 1;
    });

    if (articlesArranged.length > 0) {
      articlesArranged[0].isFeatured = true;
    }

    return articlesArranged;
  };

  return isLoading ? (
    <PulseLoader />
  ) : (
    <Layout>
      <QueueAnim type="right" delay={10} duration={1500}>
        <Row gutter={8} className="blog-page">
          <BlogView
            id={id}
            articles={articles}
            _vote={_vote}
            closeModalSignal={closeModalSignal}
          />
          <Drawer
            placement="bottom"
            closable
            onClose={() => _toggleDrawer(false)}
            visible={openDrawer}
            key="drawer-blog"
            height={386}
            zIndex={1199}
            bodyStyle={{ height: 375, paddingBottom: 65, overflowY: 'overlay' }}
          >
            <div className="layout-width">
              <BlogWrapped
                articles={drawerArticles.map((art) => {
                  art.isFeatured = false;
                  return art;
                })}
                _vote={_vote}
                _toggleDrawer={(v, _id) => {
                  _toggleDrawer(v, _id);
                }}
                inDrawer
                setCloseModalSignal={_setCloseModalSignal}
                closeModalSignal={closeModalSignal}
              />
            </div>
          </Drawer>
          <BlogWrapped
            articles={rearrangeBlogsAndSetFeatured(articles)}
            _vote={_vote}
            _toggleDrawer={_toggleDrawer}
            closeModalSignal={closeModalSignal}
          />
        </Row>
      </QueueAnim>
      <BlogEdit />
    </Layout>
  );
};

Blog.defaultProps = {
  articles: [],
  drawerArticles: [],
};

Blog.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      Title: PropTypes.string.isRequired,
      Text: PropTypes.string.isRequired,
      Author: PropTypes.shape({}).isRequired,
    })
  ),
  _listBlogs: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  _vote: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  fetchUsers: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.any,
  }).isRequired,
  openDrawer: PropTypes.bool.isRequired,
  _toggleDrawer: PropTypes.func.isRequired,
  drawerArticles: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      Title: PropTypes.string.isRequired,
      Text: PropTypes.string.isRequired,
      Author: PropTypes.shape({}).isRequired,
    })
  ),
};

export default Blog;
