import BackgroundImage from '../../assets/login_background.jpg';

const Styles = {
  image: {
    width: '80%',
    maxWidth: '400px',
    display: 'block',
    top: '47%',
    position: 'relative',
    transform: 'translateY(-50%)',
    margin: 'auto',
  },
  container: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${BackgroundImage})`,
    position: 'relative',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
};

export default Styles;
