import React from 'react';
import PropTypes from 'prop-types';
import Radio from 'antd/es/radio';
import Progress from 'antd/es/progress';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import { connect } from 'react-redux';
import find from 'lodash.find';

import BrowserStorage from '../../utils/browserStorage';
import { LOGGED_USER_OBJ } from '../../utils/StorageKeys';
import PollsResults from './PollsResults';

const getCurrentProfilesChoice = (currentProfileId, results) => {
  let choice = null;

  results.forEach((res) => {
    if (res.voters.includes(currentProfileId)) {
      // eslint-disable-next-line prefer-destructuring
      choice = res.choice;
    }
  });

  return choice;
};

const getTotalOfVotes = ({ results }) => {
  let total = 0;

  results.forEach((res) => {
    total += res.voters.length;
  });

  return total;
};

const Polls = ({ _article, vote, _articles }) => {
  const article = find(_articles, (art) => art.id === _article.id);

  if (!article || !article.polls || !article.polls.enabled) {
    return null;
  }

  const { polls } = article;
  const currentProfile = BrowserStorage.get(LOGGED_USER_OBJ, true);
  const currentProfilesChoice = getCurrentProfilesChoice(
    currentProfile.id,
    polls.results
  );
  const totalOfVotes = getTotalOfVotes(polls);

  return (
    <div className="polls">
      <h4 className="polls polls-title">
        {polls.label ? polls.label : 'No question'}
      </h4>
      <div className="polls polls-choices">
        <Radio.Group
          size="large"
          defaultValue={currentProfilesChoice}
          onChange={(e) => vote(currentProfile.id, e.target.value)}
          buttonStyle="solid"
        >
          {polls.choices.map((choice) => (
            <Radio.Button key={`choice-${choice.id}`} value={choice.id}>
              {choice.label}
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>
      <div className="polls polls-chart">
        <Row>
          {polls.results.map((res) => {
            const { label } = polls.choices[res.choice];
            const percentage = Math.round(
              (res.voters.length * 100) / totalOfVotes
            );

            return (
              <React.Fragment key={`polls-results-${res.choice}`}>
                <Col xs={24}>
                  <h3>{`${label} (${res.voters.length} out of ${totalOfVotes})`}</h3>
                </Col>
                <Col xs={24}>
                  <Progress percent={percentage} />
                </Col>
              </React.Fragment>
            );
          })}
        </Row>
        <PollsResults article={article} />
      </div>
    </div>
  );
};

Polls.defaultProps = {
  _article: {},
  // eslint-disable-next-line no-console
  vote: (id, choiceId) => {
    console.log(id, choiceId);
  },
  _articles: [],
};

Polls.propTypes = {
  _article: PropTypes.shape({}),
  vote: PropTypes.func,
  _articles: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = ({ Blog }) => ({
  _articles: Blog.articles,
});

export default connect(mapStateToProps)(Polls);
