// import Icon from 'antd/es/icon';
import Col from 'antd/es/col';

import PropTypes from 'prop-types';
import React from 'react';
import _get from 'lodash.get';
import { connect } from 'react-redux';
// import SocialMediaButtons from './SocialMediaButtons';
import defaultUserImage from '../../assets/user_default.jpeg';
import { showUserInDeltagereModal } from '../../redux/modules/App';

const mapDispatchToProps = (dispatch) => ({
  // toggleModal: () => dispatch(toggleDeltagereModal()),
  setCurrentUser: (id) => dispatch(showUserInDeltagereModal(id)),
});

// const twoOrOneLineClass = 'two-or-one-line';

// eslint-disable-next-line react/prop-types
/* const renderPhoneNumber = ({ Phone }) =>
  Phone ? (
    <h5 className={twoOrOneLineClass}>
      <Icon type="phone" /> {Phone}
    </h5>
  ) : (
    <h5 className={twoOrOneLineClass}>
      <Icon type="phone" />
      {' - '}
    </h5>
  ); */

// eslint-disable-next-line react/prop-types
/* const renderEmail = ({ email }) =>
  email ? (
    <h5 className={twoOrOneLineClass}>
      <Icon type="mail" /> {email}
    </h5>
  ) : (
    <h5 className={twoOrOneLineClass}>
      <Icon type="mail" />
      {' - '}
    </h5>
  ); */

const DeltagereEntry = ({ profile, toggleModal, setCurrentUser }) => {
  let backgroundImage = _get(profile, 'Photo.url', defaultUserImage);

  if (backgroundImage !== defaultUserImage) {
    backgroundImage = `${process.env.REACT_APP_BACKEND_URL}${backgroundImage}`;
  }

  return (
    <Col
      xl={6}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      // style={{ padding: '0.3em' }}
      style={{ paddingBottom: '0.3em', paddingTop: '0.3em' }}
      className="deltagereCard"
    >
      <a
        onClick={(e) => {
          // do not open modal if social buttons are clicked
          if (
            e.target.className &&
            e.target.className.includes('social-button')
          ) {
            return false;
          }
          setCurrentUser(profile.id);
          toggleModal();

          return true;
        }}
        role="button"
        tabIndex={0}
        className="a-without-style"
      >
        <article className="profile-card-v2 h-100">
          <div
            className="image"
            style={{ backgroundImage: `url(${backgroundImage})` }}
            alt="avatar"
          />
          <span style={{fontSize: 16}}>{profile.username}</span>
          {/* <p style={{ marginBottom: '0.875rem' }}>
            {renderPhoneNumber(profile)}
            {renderEmail(profile)}
          </p>
      <SocialMediaButtons profile={profile} /> */}
        </article>
      </a>
    </Col>
  );
};

DeltagereEntry.propTypes = {
  profile: PropTypes.shape({
    Photo: PropTypes.shape({}),
    username: PropTypes.string.isRequired,
    Description: PropTypes.string,
    Phone: PropTypes.string,
    Desc: PropTypes.string,
  }).isRequired,
  toggleModal: PropTypes.func.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(DeltagereEntry);
