import { connect } from 'react-redux';
import {
  _fetchInvesteringer,
  _setKrypto,
  _setInvestments,
} from '../../../redux/modules/Investments';

import Investeringer from '../../../components/Investeringer';

const mapStateToProps = (state) => ({
  totalPerYear: state.Investments.totalPerYear,
  investments: state.Investments.investments,
  loading: state.Investments.loading,
  historyLoading: state.Investments.historyLoading,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: () => dispatch(_fetchInvesteringer()),
  setKrypto: (payload) => dispatch(_setKrypto(payload)),
  setInvestments: (payload) => dispatch(_setInvestments(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Investeringer);
