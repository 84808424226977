import React from 'react';

const profilePic = (imgPath, size, styling = {}) => {
  const styleToApply = Object.assign(
    {},
    {
      backgroundImage: `url(${imgPath})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      width: `${size}px`,
      height: `${size}px`,
      overflow: 'hidden',
      borderRadius: `${size}px`,
      margin: 'auto',
      display: 'block',
      float: 'none',
    },
    styling
  );

  return <div style={styleToApply} />;
};

export default profilePic;
