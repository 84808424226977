import React from 'react';
import PropTypes from 'prop-types';
import Form from 'antd/es/form';
import Modal from 'antd/es/modal';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Divider from 'antd/es/divider';

const { TextArea } = Input;

const FormModal = ({
  visible,
  form,
  title,
  save,
  onClose,
  saveButtonDisabled,
}) => (
  <Modal visible={visible} destroyOnClose footer={null} onCancel={onClose}>
    <div className="modal-container">
      <Form
        layout="vertical"
        onSubmit={(e) => {
          e.preventDefault();
          form.validateFields((err, values) => {
            if (!err) {
              save(values);
            }
          });
        }}
      >
        <h3>
          <strong>{title}</strong>
        </h3>
        <Divider />
        <Form.Item label="Title">
          {form.getFieldDecorator('title', {
            rules: [
              {
                required: true,
                message: 'Title is required',
              },
            ],
          })(<Input size="large" />)}
        </Form.Item>
        <Form.Item label="Description">
          {form.getFieldDecorator('description', {
            rules: [
              {
                required: true,
                message: 'Description is required',
              },
            ],
          })(<TextArea />)}
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          style={{ float: 'right' }}
          loading={saveButtonDisabled}
        >
          Send
        </Button>
        <div style={{ clear: 'both', width: '100%', height: '0' }} />
      </Form>
    </div>
  </Modal>
);

FormModal.propTypes = {
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  form: PropTypes.shape({}).isRequired,
  save: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  saveButtonDisabled: PropTypes.bool.isRequired,
};

export default Form.create({ name: 'Modal Form' })(FormModal);
