import DeltagereModel from '../models/Deltagere';
import { getUsers, updateUser, uploadFile } from '../../api/Methods';
import { notifyFailure, notifySuccess } from '../../utils/notifications';
import errors from '../../utils/errors';

const REQUEST_USERS_LIST = 'deltagere/request_users_list';
const USERS_LIST_SUCCESS = 'deltagere/request_users_list_success';
const USERS_LIST_FAILURE = 'deltagere/request_users_list_failure';
const REQUEST_USER_UPDATE = 'deltagere/request_user_update';
const USER_UPDATE_SUCCESS = 'deltagere/user_update_success';
const USER_UPDATE_FAILURE = 'deltagere/user_update_failure';
const STORE_USER = 'deltagere/store_user';
const MODAL_TOGGLE_EDIT = 'deltagere/modal_toggle_edit';

const defaultState = new DeltagereModel();

const reducer = (state = defaultState, action) => {
  // catch thunks
  if (action === undefined) {
    return state;
  }

  switch (action.type) {
    case REQUEST_USERS_LIST:
    case USERS_LIST_FAILURE:
      return state.toggleLoading();
    case USERS_LIST_SUCCESS:
      return state
        .storeUsers(action.payload)
        .arrangeDisplayOrder()
        .toggleLoading()
        .buildSectorOpts();

    case REQUEST_USER_UPDATE:
      return state.updateUserStarts();
    case USER_UPDATE_SUCCESS:
    case USER_UPDATE_FAILURE:
      return state.updateUserStops();

    case MODAL_TOGGLE_EDIT:
      return state.toggleEditMode();

    case STORE_USER:
      return state.storeUser(action.payload);
    default:
      return state;
  }
};

export const loadUsers = () => (dispatch) => {
  // Make deltagere to load
  dispatch({ type: REQUEST_USERS_LIST });

  // Make the request now
  getUsers()
    .then((response) => {
      dispatch({ type: USERS_LIST_SUCCESS, payload: response.data || [] });
    })
    .catch((err) => {
      dispatch({ type: USERS_LIST_FAILURE });
      errors.handle(err, dispatch);
      // notifyFailure('Error on fetching users list', err.message);
    });
};

export const updateUserProfile = (userId, datas) => (dispatch) => {
  // Make the modal to load first
  dispatch({ type: REQUEST_USER_UPDATE });

  // Make the request now
  return updateUser(userId, datas)
    .then((response) => {
      dispatch({ type: STORE_USER, payload: response.data });
      dispatch({ type: USER_UPDATE_SUCCESS });
      notifySuccess('Profile updated');
    })
    .catch((err) => {
      dispatch({ type: USER_UPDATE_FAILURE });
      notifyFailure('Error on updating your profile', err.message);
    });
};

export const updateUserProfileWithFile = (userId, file, values) => (
  dispatch
) => {
  // Make the modal to load first
  dispatch({ type: REQUEST_USER_UPDATE });

  const formData = new FormData();
  formData.append('files', file);

  return uploadFile(formData)
    .then((res) => {
      const _values = { ...values };
      // add the file
      _values.Photo = res.data;
      return updateUser(userId, _values)
        .then((response) => {
          dispatch({ type: STORE_USER, payload: response.data });
          dispatch({ type: USER_UPDATE_SUCCESS });
          notifySuccess('Profile updated');
        })
        .catch((err) => {
          dispatch({ type: USER_UPDATE_FAILURE });
          notifyFailure('Error on updating your profile', err.message);
        });
    })
    .catch((err) => {
      dispatch({ type: USER_UPDATE_FAILURE });
      notifyFailure('Error on updating your profile', err.message);
    });
};

export const toggleModalEditMode = () => ({
  type: MODAL_TOGGLE_EDIT,
});

export default reducer;
