const localStorage = require('localStorage');

class BrowserStorage {
  constructor() {
    // use localstorage for now
    this.storage = localStorage;
  }

  get(key, isObject = false) {
    const val = this.storage.getItem(key);
    if (isObject) {
      return JSON.parse(val);
    }
    return val;
  }

  set(key, val, isObject = false) {
    const valToStore = isObject ? JSON.stringify(val) : val;
    return this.storage.setItem(key, valToStore);
  }

  clear() {
    return this.storage.clear();
  }
}

export default new BrowserStorage();
