/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import Table from 'antd/es/table';

import { randomColor } from '../../constants/chart';
import NumbersHelper from '../../utils/numbers';

const ChartLegend = ({ data, columns, sortFunc, onRowClick }) => {
  const renderDatas = (column) => (text, record) => {
    if (column.isLegend) {
      let recordColor = record.color ? record.color : randomColor();
      recordColor = !recordColor.startsWith('#')
        ? `#${recordColor}`
        : recordColor;
      return (
        <div className="legend-title-container">
          <div
            className="legend-square-color"
            style={{ backgroundColor: recordColor }}
          />
          <label>{record[column.dataIndex]}</label>
        </div>
      );
    }
    const value = !Number.isNaN(Math.round(text)) ? Math.round(text) : 0;

    return (
      <div style={{ textAlign: 'right' }}>
        <strong>{NumbersHelper.separateWith(value, ' ')}</strong>
      </div>
    );
  };
  const columnsWithRenderer = () =>
    columns.map((column) => ({
      render: renderDatas(column),
      ...column,
    }));

  return (
    <Table
      className="chartlegend-table"
      dataSource={data}
      columns={columnsWithRenderer()}
      pagination={false}
      size="small"
      bordered={false}
      onChange={(pagination, filter, sorter) => {
        if (typeof sortFunc === 'function') sortFunc(sorter);
      }}
      onRowClick={onRowClick}
    />
  );
};

export default ChartLegend;
