// eslint-disable-next-line import/prefer-default-export
export const chartColors = {
  'e-Sakafo SARL': {
    backgroundColor: '#f12323',
    hoverBackgroundColor: '#f16464',
  },
  Bankinnskudd: {
    backgroundColor: '#04e395',
    hoverBackgroundColor: '#4bf5b9',
  },
  'Netfair As': {
    backgroundColor: '#feb019',
    hoverBackgroundColor: '#f9ca6e',
  },
  'Curious Graph AS': {
    backgroundColor: '#ff4560',
    hoverBackgroundColor: '#fb8f9e',
  },
  Coolbet: {
    backgroundColor: '#765dd0',
    hoverBackgroundColor: '#9281d4',
  },
  Test: {
    backgroundColor: '#008ffb',
    hoverBackgroundColor: '#72c1fd',
  },
  'FTX Token': {
    backgroundColor: '#00E5FF',
    hoverBackgroundColor: '#84FFFF',
  },
  Bitcoin: {
    backgroundColor: '#FB8C00',
    hoverBackgroundColor: '#FFA726',
  },
  Elastos: {
    backgroundColor: '#424242',
    hoverBackgroundColor: '#757575',
  },
};

export const randomColor = () =>
  `#${((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0')}`;
