/* eslint-disable no-prototype-builtins */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-str */
import React, { Component } from 'react';
import Modal from 'antd/es/modal';
import Select from 'antd/es/select';
import Switch from 'antd/es/switch';
import Icon from 'antd/es/icon';
import Upload from 'antd/es/upload';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Form from 'antd/es/form';
import omit from 'lodash.omit';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { connect } from 'react-redux';
import isEqual from 'lodash.isequal';

import { uploadFile } from '../../api/Methods';
import browserStorage from '../../utils/browserStorage';
import { LOGGED_USER_OBJ } from '../../utils/StorageKeys';
import {
  closeModal,
  saveBlog,
  saveBlogWithFile,
} from '../../redux/modules/Blog';
import { notifyFailure } from '../../utils/notifications';
import SelectTag from '../TagBlog/SelectTag';

const formItemLayout = {
  wrapperCol: { span: 24 },
  layout: 'vertical',
};

class BlogEdit extends Component {
  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
    this.editor = null;
    // eslint-disable-next-line react/prop-types
    const { currentArticleInEdit } = props;
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      fileEntry: null,
      imageUrl: currentArticleInEdit
        ? currentArticleInEdit.feature_image
          ? `${process.env.REACT_APP_BACKEND_URL}${currentArticleInEdit.feature_image.url}`
          : null
        : null,
      pollsActive:
        currentArticleInEdit &&
        currentArticleInEdit.polls &&
        currentArticleInEdit.polls.enabled,
    };
  }

  save(e) {
    e.preventDefault();

    if (!this.editor) {
      notifyFailure('Error', 'Editor could not be found');
      return false;
    }

    // eslint-disable-next-line react/prop-types
    const { form } = this.props;

    return form.validateFields(
      (err, values) => {
        if (!err) {
          this._persistInBack(this, values);
        }
      },
      { force: true }
    );
  }

  votersNeedToBeUpdated(currentArticleInEdit, values) {
    let votersToReset = false;
    if (!currentArticleInEdit || !currentArticleInEdit.polls) {
      votersToReset = true;
    } else if (
      currentArticleInEdit.polls.label &&
      currentArticleInEdit.polls.label !== values.polls_question
    ) {
      votersToReset = true;
    }

    if (!currentArticleInEdit) {
      return votersToReset;
    }

    if (currentArticleInEdit.polls) {
      const originalChoices = (currentArticleInEdit.polls.choices || []).map(
        (e) => e.label
      );
      if (!isEqual(originalChoices, values.polls_choices)) {
        votersToReset = true;
      }
    }

    return votersToReset;
  }

  _persistInBack(context, values) {
    const {
      _saveBlog,
      _closeModal,
      blogId,
      _saveBlogWithFile,
      currentArticleInEdit,
    } = context.props;
    // Handle rich text
    const editorValue = context.editor.getContent();
    // Handle file uploads
    const { fileEntry } = context.state;

    let finalObject = Object.assign({}, values, {
      Text: editorValue,
      Author: browserStorage.get(LOGGED_USER_OBJ, true),
    });

    // Handle polls
    const updateVoters = this.votersNeedToBeUpdated(
      currentArticleInEdit,
      values
    );

    if (values.polls_enabled) {
      finalObject.polls = {
        enabled: values.polls_enabled,
        label: values.polls_question,
        choices: values.polls_choices.map((choice, index) => ({
          id: index,
          label: choice,
        })),
        results: values.polls_choices.map((choice2, index2) => ({
          choice: index2,
          voters: updateVoters
            ? []
            : currentArticleInEdit.polls.results[index2].voters,
        })),
        anonymous: values.polls_anonymous,
      };
    } else {
      finalObject.polls = { enabled: false };
    }

    finalObject = omit(finalObject, [
      'polls_choices',
      'polls_enabled',
      'polls_question',
      'polls_anonymous',
    ]);
    if (!fileEntry) {
      _saveBlog(blogId, finalObject).then(() => {
        _closeModal();
      });
    } else {
      _saveBlogWithFile(blogId, finalObject, fileEntry).then(() => {
        _closeModal();
      });
    }
  }

  render() {
    const {
      // eslint-disable-next-line react/prop-types
      form,
      isSaving,
      currentArticleInEdit,
    } = this.props;
    const currentArticle = currentArticleInEdit;
    const { imageUrl } = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const polls = currentArticle ? currentArticle.polls : null;
    // set polls anonymous by default
    let pollsIsAnonymous = true;
    if (polls !== null && polls.hasOwnProperty('anonymous')) {
      pollsIsAnonymous = polls.anonymous;
    }

    const { setFieldsValue } = form;

    return (
      <Form {...formItemLayout} onSubmit={this.save}>
        <Form.Item label="Title">
          {form.getFieldDecorator('Title', {
            rules: [
              {
                required: true,
                message: 'You must enter a title',
              },
            ],
            initialValue: currentArticle ? currentArticle.Title : '',
          })(<Input size="large" placeholder="Title" />)}
        </Form.Item>
        <Form.Item label="Featured Image">
          {form.getFieldDecorator('Feature_image')(
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              method="POST"
              action={`${process.env.REACT_APP_BACKEND_URL}upload`}
              beforeUpload={(file) => {
                this.setState({
                  // eslint-disable-next-line react/no-unused-state
                  fileEntry: file,
                  imageUrl: URL.createObjectURL(file),
                });
                return false;
              }}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
              ) : (
                uploadButton
              )}
            </Upload>
          )}
        </Form.Item>
        <Form.Item label="Content">
          {form.getFieldDecorator('Text')(
            <Editor
              apiKey="ebroavkavjuqkm7iih4pktsnb14gl8ymk44u7y8iz21shxzv"
              initialValue={currentArticle ? currentArticle.Text : ''}
              init={{
                min_height: 500,
                menubar: true,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor autoresize',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount imagetools emoticons',
                ],
                image_advtab: true,
                style_formats: [
                  {
                    title: 'Image Left',
                    selector: 'img',
                    styles: {
                      float: 'left',
                      margin: '0 10px 0 10px',
                    },
                  },
                  {
                    title: 'Image Right',
                    selector: 'img',
                    styles: {
                      float: 'right',
                      margin: '0 10px 0 10px',
                    },
                  },
                ],
                toolbar:
                  'undo redo | formatselect | bold italic forecolor backcolor | \
              alignleft aligncenter alignright alignjustify | media image|\
              bullist numlist outdent indent emoticons | removeformat | help',
                images_upload_url: `${process.env.REACT_APP_BACKEND_URL}upload`,
                images_upload_handler: (blobInfo, success, failure) => {
                  const formData = new FormData();
                  formData.append(
                    'files',
                    blobInfo.blob(),
                    blobInfo.filename()
                  );

                  uploadFile(formData)
                    .then((response) => {
                      if (!response.data[0] || !response.data[0].url) {
                        return failure('File not uploaded correctly');
                      }
                      return success(response.data[0].url);
                    })
                    .catch((err) => failure(err.message));
                },
                setup: (editor) => {
                  this.editor = editor;
                },
              }}
            />
          )}
        </Form.Item>

        <Form.Item label="Enable Polls (Updating poll will reset all votes)">
          {form.getFieldDecorator('polls_enabled', {
            initialValue: polls && polls.enabled,
          })(
            <Switch
              defaultChecked={polls && polls.enabled}
              onChange={(checked) => this.setState({ pollsActive: checked })}
            />
          )}
        </Form.Item>

        <Form.Item label="Poll Question (Updating poll will reset all votes)">
          {form.getFieldDecorator('polls_question', {
            rules: [
              { required: this.state.pollsActive, message: 'Input a question' },
            ],
            initialValue: !currentArticle
              ? ''
              : currentArticle.polls
              ? currentArticle.polls.label
              : '',
          })(<Input type="text" disabled={!this.state.pollsActive} />)}
        </Form.Item>

        <Form.Item label="Poll Choices (Updating poll will reset all votes)">
          {form.getFieldDecorator('polls_choices', {
            rules: [
              {
                required: this.state.pollsActive,
                message: 'Input at least one choice',
              },
            ],
            initialValue: !currentArticle
              ? []
              : currentArticle.polls && currentArticle.polls.enabled
              ? currentArticle.polls.choices.map((choice) => choice.label)
              : [],
          })(
            <Select
              mode="tags"
              placeholder="Press enter to add choice"
              className="polls-choices"
              // eslint-disable-next-line react/destructuring-assignment
              disabled={!this.state.pollsActive}
              dropdownStyle={{ display: 'none' }}
            />
          )}
        </Form.Item>
        <Form.Item>
          {form.getFieldDecorator('RemovedTags', {
            initialValue: []
          })(
            <div />
          )}
        </Form.Item>
        <Form.Item label="Tag blog post">
          {form.getFieldDecorator('Tags', {
            initialValue: currentArticle ? (currentArticle.Tags || []) : [],
          })(
            <SelectTag onChange={(values, deletedValues) => {
              setFieldsValue({'Tags': values});
              setFieldsValue({'RemovedTags': deletedValues});
            }} blog={currentArticle}/>
          )}
        </Form.Item>
        
        <Form.Item label="Anonymous polls">
          {form.getFieldDecorator('polls_anonymous', {
            initialValue: pollsIsAnonymous,
          })(
            <Switch
              defaultChecked={pollsIsAnonymous}
              disabled={!this.state.pollsActive}
            />
          )}
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          style={{ float: 'right' }}
          loading={isSaving}
        >
          Save
        </Button>
      </Form>
    );
  }
}

const mapStateToProps = ({ Blog }) => ({
  modalOpen: Blog.modalOpen,
  isSaving: Blog.isSaving,
  blogId: Blog.blogId,
  articles: Blog.articles,
  currentArticleInEdit: Blog.currentArticleInEdit,
});

const mapDispatchToProps = (dispatch) => ({
  _closeModal: () => dispatch(closeModal()),
  _saveBlog: (id, datas) => dispatch(saveBlog(id, datas)),
  _saveBlogWithFile: (id, datas, file) =>
    dispatch(saveBlogWithFile(id, datas, file)),
});

const BlogEditWrapped = Form.create({ name: 'blog_edit_form' })(BlogEdit);

const BlogEditModal = ({ _closeModal, modalOpen, ...rest }) => (
  <Modal
    visible={modalOpen}
    footer={null}
    onCancel={() => {
      _closeModal();
    }}
    centered
    className="modal-blog"
    destroyOnClose
  >
    <div className="container layout-width" style={{paddingTop: 0}}>
      <BlogEditWrapped _closeModal={_closeModal} {...rest} />
    </div>
  </Modal>
);

BlogEditModal.defaultProps = {
  blogId: null,
  currentArticleInEdit: null,
  modalOpen: false,
};

BlogEditModal.propTypes = {
  blogId: PropTypes.string,
  modalOpen: PropTypes.bool,
  _closeModal: PropTypes.func.isRequired,
  _saveBlog: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  currentArticleInEdit: PropTypes.shape({}),
  _saveBlogWithFile: PropTypes.func.isRequired,
};

BlogEdit.defaultProps = Object.assign({}, { ...BlogEditModal.defaultProps });

BlogEdit.propTypes = Object.assign(
  {},
  { ...BlogEditModal.propTypes },
  {
    form: PropTypes.shape({
      getFieldsValue: PropTypes.func,
      getFieldDecorator: PropTypes.func,
    }).isRequired,
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(BlogEditModal);
