import { findIndex, forEach, groupBy, chain, sortBy, reverse, find, sumBy, map } from "lodash";
import { randomColor } from '../constants/chart';

const formatDate = (date) => { const sp = (date || '').split('T'); return sp[0] ? sp[0] : date;}

const buildChartDatas = (datas, dates, view = 'name') => {
    const byView = groupBy(datas, view);
    
    const res = [];
    forEach(byView, (his, key) => {
        const data = [];
        if(view === 'name') {
          forEach(his, (h) => {
              const dateIndex = findIndex(dates, (d) => d === h.createdAt );
              data.splice(dateIndex, 0, h.amount);
          });
        }
        
        res.push({ name: key, data });
    });

    return res;
}

const _buildChartDatas = (datas, view = 'all', total = true) => {
  const dates = [];
  const series = {};
  const dataSeries = chain(datas)
  /* .map((data) => {
      const newData = {...data};
      // const sp = (data.createdAt || "").split("T");
      // const sp = moment(data.createdAt, "DD.MM.YYYY");
      const sp = moment(data.createdAt).format("DD/MM/YYYY")
      // const sp = moment.utc(data.createdAt);
      /* if(sp && sp.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        newData.createdAt = sp[0];
      } 
      newData.createdAt = sp;

      return newData;
  }) */
  .sortBy('createdAt')
  .groupBy('createdAt')
  .map((hist, date) => {
    dates.push(date);
    return chain(hist)
    .groupBy(view)
    .map((his, name) => ({
      name: name.replace(/_/gi, " "),
      amount: sumBy(his, h => parseFloat(h.amount))
    }))
    // .filter((his) => his.amount > 0)
    .value();
  })
  .value();

  if(total) {
    const totalDataSeries = [];
    forEach(dataSeries, (serie) => {
      const t = sumBy(serie, s => s.amount);
      totalDataSeries.push(t);
    });

    return {dates, series: [{name: 'Total', data: totalDataSeries}]};
  }
  
  forEach(dataSeries, (histories) => {
    forEach(histories, (his) => {
      if(!series[his.name]) {
        series[his.name] = [];
      }
      series[his.name].push(his.amount);
    });
  });

  const asSeries = map(series, (data, name) => ({ name, data }) );
  
  /* if(total) {
    const data = {};
    for(let j = 0; j < asSeries.length; j += 1) {
      for(let i = 0; i < asSeries[j].data.length; i += 1) {
        if(!data[i]) {
          data[i] = 0;
        }
        data[i] += asSeries[j].data[i];
      }      
    };
    return { dates, series: [{name: 'Total', data }]};
  } */ 

  return { dates, series: asSeries};
}

const handleUndefinedSektors = (investment) => {
    if (!investment.sektor) {
      return { ...investment, sektor: "No sektor" };
    }

    return investment;
  };

  const _groupBy = (_inv, groupName = "Type") =>
    chain(_inv)
      .map(handleUndefinedSektors)
      .groupBy(groupName)
      .map((i, type) => {
        // try to set a color from the original investments
        // and assign the first matching investment of type color to the group
        const investmentOfType = find(i, (o) => o[groupName] === type);
        const hasColor = investmentOfType && investmentOfType.color;

        return {
          Name: type.replace(/_/gi, " "),
          displayAmount: sumBy(i, (o) => o.Amount),
          color: hasColor ? investmentOfType.color : randomColor(),
        };
      })
      .value();
  const _sortBy = (_inv, key, asc = true) => {
    const _tempSorted = sortBy(_inv, key);
    if (!asc) reverse(_tempSorted);

    return _tempSorted;
  };


export default {
    buildChartDatas,
    formatDate,
    _groupBy,
    _sortBy,
    _buildChartDatas
}