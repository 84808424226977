/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/self-closing-comp */
import React, { useEffect } from 'react';
import Typography from 'antd/es/typography';
import Form from 'antd/es/form';
import Icon from 'antd/es/icon';
import Input from 'antd/es/input';
import Checkbox from 'antd/es/checkbox';
import Button from 'antd/es/button';
import get from 'lodash.get';
import { Link, /* useLocation */ } from 'react-router-dom';
import PropTypes from 'prop-types';
import { notifyFailure } from '../../utils/notifications';

const { Title, Text } = Typography;

/* function useQuery() {
  return new URLSearchParams(useLocation().search);
} */

const LoginForm = ({ requestLogin, form, isRequestingLogin }) => {
  // const query = useQuery();
  // const forTestPurpose = query.get('dev');

  const statusChangeCallback = (response) => {
    const accessToken = get(response, 'authResponse.accessToken', null);
    if(!accessToken) {
      notifyFailure('Error', 'Could not access FB account');
      // eslint-disable-next-line no-useless-return
      return;
    }

    requestLogin(null, null, accessToken);

  };

  const checkLoginState = () => {
      FB.getLoginStatus((response) => {
         statusChangeCallback(response);
      });
  };

  useEffect(() => {
    // if(!forTestPurpose) return;
    
    window.statusChangeCallback = statusChangeCallback;
    window.checkLoginState = checkLoginState;

    const s = '<div class="fb-login-button" ' +
                'data-width="272" ' +
                'data-size="large" ' +
                'data-auto-logout-link="false" ' +
                'data-scope="public_profile,email" ' +
                'data-button-type="continue_with" ' +
                'data-use-continue-as="true" ' +
                'data-layout="default" ' +
                'onlogin="checkLoginState"></div>';

    const div = document.getElementById('social-login-button-facebook')
    div.innerHTML = s;

    FB.XFBML.parse(div);

    // eslint-disable-next-line consistent-return
    return function cleanup() {
      delete window.statusChangeCallback;
      delete window.checkLoginState;
    }

  }, []);

  return (
    <div
      style={{
        textAlign: 'center',
        position: 'relative',
        top: '25%',
      }}
      className="login-form-responsive-padding"
    >
      <Title style={{ fontSize: '45px', fontWeight: '300' }}>
        Aksjonærportal
      </Title>
      <Text type="secondary" style={{ fontSize: '1.12rem' }}>
        Skriv inn din epostadresse og passord for å få tilgang til din konto
      </Text>
      <br />
      <br />
      <Form
        style={{ margin: 'auto' }}
        onSubmit={(e) => {
          // prevent the form to refresh the page with values
          e.preventDefault();
          const { epost, passord } = form.getFieldsValue();
          requestLogin(epost, passord);
        }}
      >
        <Form.Item>
          {form.getFieldDecorator('epost')(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Epost"
              size="large"
            />
          )}
        </Form.Item>
        <Form.Item>
          {form.getFieldDecorator('passord')(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Passord"
              size="large"
            />
          )}
        </Form.Item>
        <Form.Item>
          {form.getFieldDecorator('rememberMe')(
            <Checkbox style={{ float: 'left' }}>Husk meg</Checkbox>
          )}
          <div style={{width: '100%', display: 'flex'}}>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            style={{ width: 272, margin: 'auto' }}
            size="large"
            loading={isRequestingLogin}
          >
            Logg inn
          </Button>
          </div>
        </Form.Item>
        <div id='social-login-button-facebook'></div>
        <Form.Item style={{ lineHeight: '1rem' }}>
          <Text>
            Er du ikke aksjonær?{' '}
            <Link className="login-form-forgot" to="/om">
              Les mer
            </Link>
          </Text>
          <br />
          <Text>
            Glemt passordet ditt? <Link to="/resett">Resett passord</Link>
          </Text>
        </Form.Item>
      </Form>
    </div>
  );
};

LoginForm.defaultProps = {
  isRequestingLogin: false,
};

LoginForm.propTypes = {
  requestLogin: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldsValue: PropTypes.func,
    getFieldDecorator: PropTypes.func,
  }).isRequired,
  isRequestingLogin: PropTypes.bool,
};

const TheForm = Form.create({ name: 'login' })(LoginForm);
export default TheForm;
