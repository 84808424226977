/* eslint-disable func-names */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import List from 'antd/es/list';
import Button from 'antd/es/button';
import Icon from 'antd/es/icon';
import Divider from 'antd/es/divider';
import get from 'lodash.get';
import moment from 'moment';
import marked from 'marked';

// import QueueAnim from 'rc-queue-anim';
import PropTypes from 'prop-types';
import Timeline from '../Timeline';

import horseIcon from '../../assets/horse_icon.png';
import eventIcon from '../../assets/events_icon.png';
import travelIcon from '../../assets/travel_icon.png';
import PulseLoader from '../PulseLoader';

// eslint-disable-next-line import/imports-first
import 'moment/locale/nb';

// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

moment.locale('nb');

const IconText = ({ type, text }) => (
  <span>
    <Icon type={type} style={{ marginRight: 8 }} />
    {text}
  </span>
);

IconText.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const Events = ({ events, fetch, loading }) => {
  useEffect(() => {
    if (events.length === 0) {
      fetch();
    }
  }, []);

  const makeEventIcon = (item) => {
    if (item.Type === 'event') {
      return <img src={eventIcon} alt="icon_logo" style={{ width: '50px' }} />;
    }
    if (item.Type === 'travel') {
      return (
        <div className="event-icon item-event">
          <img src={travelIcon} alt="icon_logo" style={{ width: '75%' }} />
        </div>
      );
    }

    return <img src={horseIcon} alt="icon_logo" style={{ width: '50px' }} />;
  };

  // eslint-disable-next-line no-unused-vars
  const renderListItem = (datas = [], year = '2020', key = 0) => (
    <div key={key}>
      <Divider>{year}</Divider>
      <List
        itemLayout="vertical"
        loading={loading}
        size="large"
        dataSource={datas}
        renderItem={(item) => {
          const imgElem = (
            <img
              // width={272}
              alt="logo"
              style={{ objectFit: 'cover', height: '193px' }}
              src={
                process.env.REACT_APP_BACKEND_URL + get(item, 'Cover.url', '')
              }
            />
          );

          const avatarElem = (
            <div className="ant-list-item-meta-avatar">
              {makeEventIcon(item)}
            </div>
          );

          return (
            <>
              <List.Item
                key={item.Title || ''}
                actions={[
                  item.Status === 'finished' ? (
                    <Button
                      type="primary"
                      size="small"
                      disabled
                      style={{ backgroundColor: 'green', color: 'white' }}
                    >
                      Gjennomført
                    </Button>
                  ) : item.Status === 'cancelled' ? (
                    <Button
                      type="primary"
                      size="small"
                      disabled
                      style={{ backgroundColor: 'red', color: 'white' }}
                    >
                      Avlyst
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      size="small"
                      href={item.SubscribeLink || '#'}
                      target="_blank"
                    >
                      Trykk her for påmelding
                    </Button>
                  ),
                ]}
                extra={imgElem}
              >
                <List.Item.Meta
                  avatar={avatarElem}
                  title={item.Title || ''}
                  description={
                    <div>
                      <IconText
                        type="environment"
                        text={item.Location || 'Nowhere'}
                      />
                      {' on '}
                      <span>
                        {moment(item.Date)
                          .format('dddd DD MMMM YYYY, H:mm')
                          .capitalize()}
                      </span>
                    </div>
                  }
                />
                <span
                  dangerouslySetInnerHTML={{
                    __html: marked(item.Content || ''),
                  }}
                />
              </List.Item>
            </>
          );
        }}
      />
    </div>
  );

  const eventsPerYear = {};
  events.forEach((event) => {
    const eventYear = moment(event.Date || undefined).format('YYYY');
    if (Array.isArray(eventsPerYear[eventYear])) {
      eventsPerYear[eventYear].push(event);
    } else {
      eventsPerYear[eventYear] = [event];
    }
  });

  const yearOrder = Object.keys(eventsPerYear).sort(
    (a, b) => parseInt(b, 10) - parseInt(a, 10)
  );

  console.log('YEAR ORDER ->', yearOrder);

  return loading ? (
    <PulseLoader />
  ) : (
    <div className="event-container">
      <Timeline events={eventsPerYear} />
    </div>
    /*   {
      yearOrder.map((year) => renderListItem(eventsPerYear[year], year, year))
    } */
  );
};

Events.defaultProps = {
  events: [],
};

Events.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      Title: PropTypes.string.isRequired,
      Content: PropTypes.string.isRequired,
      Date: PropTypes.string.isRequired,
    }).isRequired
  ),
  fetch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Events;
