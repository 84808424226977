/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import Select from 'react-select/async';
import { searchTag } from '../../api/Methods';

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white', '&:hover': { borderColor: '#004b82' } }),
  input: (styles) => ({ ...styles, '&:focus': { borderColor: '#004b82' } }),
  placeholder: (styles) => ({ ...styles }),
};

const SelectTag = ({blog, onChange}) => {
  const [options, setOptions] = useState([]);
  const defaultValue = blog ? (blog.Tags || []) : []
  
  const [values, setValues] = useState(defaultValue);

  useEffect(() => {
    const fetchOptions = async (inputValue) => {
      const res = await searchTag(inputValue);
      let opts = [];
      if (res && res.data) {
        opts = [
          ...['Investeringer', 'Hest', 'Event'].map((tag) => ({
            label: tag,
            options: (res.data[tag.toLocaleLowerCase()] || []).map((t) => ({
              label: t.Name || t.Title || t.name,
              value: t._id,
              model: tag.toLocaleLowerCase()
            })),
          })),
        ];
      }
      setOptions(opts);
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    const differences = defaultValue.filter(x => !values.includes(x));
    onChange(values, differences);
  }, [values])

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  if(options.length === 0) {
    return <div><Select isMulti isLoading /></div>;
  }

  return (
    <div>
        <Select 
        isMulti 
        menuPlacement="top"
        defaultOptions={options} 
        value={values}
        formatGroupLabel={formatGroupLabel} 
        styles={colourStyles} 
        onChange={(va) => {
          setValues(va);
        }}
        />
    </div>
  );
};

export default SelectTag;
