import React from 'react';
import Menu from 'antd/es/menu';
import Icon from 'antd/es/icon';
// import Dropdown from 'antd/es/dropdown';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash.get';

import profilePic from '../../utils/ProfilePic';
import {
  logout as reduxLogout,
  toggleUpdatePasswordModal,
  toggleReportModal,
  toggleFeatureModal,
  toggleDeltagereModal,
} from '../../redux/modules/App';

const profilePicLinkFor = (connectedProfile) => {
  const url = _get(connectedProfile, 'Photo.url', '');

  return url === ''
    ? `/img/user_default.jpeg`
    : `${process.env.REACT_APP_BACKEND_URL}${url}`;
};

const renderLinkWithNoClickEvt = (text, action = null) => (
  <a
    href="!#"
    className="dropdown-menu-link"
    onClick={(e) => {
      e.preventDefault();
      if (typeof action === 'function') {
        action();
      }
    }}
  >
    {text}
  </a>
);

// eslint-disable-next-line no-unused-vars
const renderRightMenu = (
  logout,
  togglePwdModal,
  connectedProfile,
  _toggleReportModal,
  _toggleFeatureModal,
  _toggleDeltagereModal
) => (
  <Menu className="right-dropdown">
    <Menu.Item key="1" style={{ textAlign: 'center' }}>
      <div style={{ padding: '1em' }}>
        {profilePic(profilePicLinkFor(connectedProfile), 90)}{' '}
        <p
          /* style={{
            marginBottom: 0,
            width: '122px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }} */
        >
          {_get(connectedProfile, 'username', 'UNKNOWN')}
        </p>
        <div style={{ margin: '0 1em' }}>
          {renderLinkWithNoClickEvt('Endre passord', () => {
            togglePwdModal();
          })}
        </div>
        <div style={{ margin: '0 1em' }}>
          {renderLinkWithNoClickEvt('Profilinnstillinger', () => {
            _toggleDeltagereModal();
          })}
          <hr className="dropdown-menu-separator" />
        </div>
      </div>
    </Menu.Item>
    <Menu.Item key="2">
      <p style={{ marginBottom: '0' }}>
        Fun Invest Portal v1.2.0
        <br />
        Levert av{' '}
        <a
          style={{ color: '#134c81' }}
          href="https://www.wau.co"
          target="_blank"
          rel="noopener noreferrer"
        >
          WAU I/O AS
        </a>
      </p>
    </Menu.Item>
    <Menu.Item key="3" onClick={_toggleFeatureModal}>
      <Icon type="cloud-upload" />
      <span>Send endringsønske</span>
    </Menu.Item>
    <Menu.Item key="4" className="last-menu" onClick={_toggleReportModal}>
      <Icon type="warning" />
      <span>Meld feil</span>
    </Menu.Item>
    <Menu.Item
      key="5"
      onClick={() => {
        logout();
      }}
    >
      <Icon type="poweroff" />
      <span>Logg ut</span>
    </Menu.Item>
  </Menu>
);

const RightDropdown = ({
  connectedProfile,
  /* logout,
  togglePwdModal,
  _toggleReportModal,
  _toggleFeatureModal, */
  _toggleDeltagereModal,
}) => (
  <a
      className="ant-dropdown-link"
      role="button"
      tabIndex={0}
      onClick={() => _toggleDeltagereModal()}
      style={{ top: '-4px', position: 'relative' }}
    >
      {profilePic(profilePicLinkFor(connectedProfile), 46, {
        margin: '12px auto',
      })}{' '}
    </a>
  /* <Dropdown
    /* overlay={renderRightMenu(
      logout,
      togglePwdModal,
      connectedProfile,
      _toggleReportModal,
      _toggleFeatureModal,
      _toggleDeltagereModal,
    )}
    trigger={['click']}
    placement="bottomRight"
  >
    <a
      className="ant-dropdown-link"
      href="!#"
      onClick={() => _toggleDeltagereModal()}
      style={{ top: '-4px', position: 'relative' }}
    >
      {profilePic(profilePicLinkFor(connectedProfile), 46, {
        margin: '12px auto',
      })}{' '}
    </a>
    </Dropdown> */
);

RightDropdown.propTypes = {
  connectedProfile: PropTypes.shape({}).isRequired,
  _toggleDeltagereModal: PropTypes.func.isRequired,
  /* logout: PropTypes.func.isRequired,
  togglePwdModal: PropTypes.func.isRequired,
  _toggleReportModal: PropTypes.func.isRequired,
  _toggleFeatureModal: PropTypes.func.isRequired, */
};

const mapStateToProps = ({ App }) => ({
  connectedProfile: App.loggedUserObj,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(reduxLogout());
  },
  togglePwdModal: () => {
    dispatch(toggleUpdatePasswordModal());
  },
  _toggleReportModal: () => {
    dispatch(toggleReportModal());
  },
  _toggleFeatureModal: () => {
    dispatch(toggleFeatureModal());
  },
  _toggleDeltagereModal: () => dispatch(toggleDeltagereModal()),
});
const ConnectedRightDropdown = connect(
  mapStateToProps,
  mapDispatchToProps
)(RightDropdown);

export default ConnectedRightDropdown;
