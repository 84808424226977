/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-console */
import React, { useEffect } from 'react';
import Layout from 'antd/es/layout';
import Icon from 'antd/es/icon';
import Button from 'antd/es/button';
import PropTypes from 'prop-types';
import QueueAnim from 'rc-queue-anim';
import ReactGA from 'react-ga';

import LogoNoF from '../../../assets/logo-no-f.png';
import layoutStyles from './styles';
import SideNavMenu from '../../SidenavMenu';
import HeaderRightDropDown from '../../HeaderRightDropdown';
import { wrapWithFirstMenuLink } from '../../../utils/ui';
import UpdatePasswordModal from '../../HeaderRightDropdown/UpdatePasswordModal';
import FormModal from '../../HeaderRightDropdown/FormModal';
import ModalDetailsForm from '../../Deltagere/EditableProfile/ModalDetailsForm';
import browserStorage from '../../../utils/browserStorage';
import { LOGGED_USER_OBJ } from '../../../utils/StorageKeys';

// All home/...routes
import RouteAllScreens from '../../../screens/home/routes';

const { Header, Sider, Content } = Layout;
const connectedProfile = browserStorage.get(LOGGED_USER_OBJ, true);
const reportingText =
  connectedProfile && connectedProfile.username
    ? `Reported by ${connectedProfile.username} [${connectedProfile.email}]\n----------\n`
    : '';

const Home = ({
  menuFolded,
  toggleMenu,
  location,
  _openModal,
  reportModalIsOpen,
  _toggleReportModal,
  _toggleFeatureModal,
  featureModalIsOpen,
  sendingTicket,
  _createTicket,
}) => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
  });

return (
  <>
    <Header style={layoutStyles.header} key={1}>
      <div
        style={{ ...layoutStyles.headerContent }}
        className="layout-width header-content"
      >
        <div className="desktop-view">
          <div>
            {wrapWithFirstMenuLink(
              <img
                src={LogoNoF}
                alt="Logo-no-f"
                style={layoutStyles.sidenav.img3}
              />
            )}
          </div>
          <SideNavMenu
            menuFolded={menuFolded}
            mode="horizontal"
            noLabel
            className="navbar-menu"
          />
        </div>
        <Icon
          className="trigger hide-on-small-and-down"
          type={menuFolded ? 'menu-unfold' : 'menu-fold'}
          style={{ opacity: 0, display: 'none' }}
        />
        <div className="header-right">
          {wrapWithFirstMenuLink(
            <img
              src={LogoNoF}
              alt="LogoFOnly"
              style={{
                height: '23px',
                display: 'inline-block',
              }}
            />,
            'logo-on-left-profile'
          )}
          <div className="right-buttons-container">
            <HeaderRightDropDown />
          </div>
        </div>
      </div>
    </Header>
    <Layout style={layoutStyles.layoutGlobal} id="home-layout">
      <Sider
        trigger={null}
        onBreakpoint={(broke) => {
          if (broke && !menuFolded) {
            toggleMenu();
          } else if (!broke && menuFolded) {
            toggleMenu();
          }
        }}
        collapsible={false}
        width={200}
        breakpoint="sm"
        id="sideMenuNav"
        className="mobile-view"
      >
        <div className="sidenav-header">
          {wrapWithFirstMenuLink(
            <img
              src={LogoNoF}
              alt="Logo-no-f"
              style={layoutStyles.sidenav.img2}
            />
          )}
        </div>
        <div className="sidenav-content">
          <SideNavMenu menuFolded={menuFolded} noLabel />
        </div>
      </Sider>
      <Layout style={{overflowX: 'unset', paddingTop: 0}}>
        <QueueAnim
          interval={500}
          duration={0}
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            // overflowX: 'hidden',
          }}
        >
          <Content
            style={{
              margin: 0,
              padding: 24,
              background: '#fff',
              minHeight: 280,
              // overflowY: 'auto',
              // overflowX: 'hidden',
            }}
            key={2}
          >
            <div className="layout-width content-layout">
              <UpdatePasswordModal />
              <ModalDetailsForm />
              <FormModal
                visible={featureModalIsOpen}
                onClose={_toggleFeatureModal}
                title="Ask for a new feature"
                save={(values) =>
                  _createTicket({
                    title: values.title,
                    description: `${reportingText}<br/>${values.description}`,
                    epicname: values.title,
                    type: 'epic',
                  })
                }
                saveButtonDisabled={sendingTicket}
              />
              <FormModal
                visible={reportModalIsOpen}
                onClose={_toggleReportModal}
                title="Report Bug"
                save={(values) =>
                  _createTicket({
                    title: values.title,
                    description: `${reportingText}<br/>${values.description}`,
                    type: 'bug',
                  })
                }
                saveButtonDisabled={sendingTicket}
              />
              {<RouteAllScreens />}
            </div>
          </Content>
          {
            // Only show blog button on blog page
            location.pathname && location.pathname === '/home' ? (
              <Button
                type="primary"
                shape="circle"
                icon="plus"
                size="large"
                className="button-floating"
                onClick={() => _openModal()}
              />
            ) : null
          }
        </QueueAnim>
      </Layout>
    </Layout>
  </>
);
}

Home.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  menuFolded: PropTypes.bool.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
  _openModal: PropTypes.func.isRequired,
  reportModalIsOpen: PropTypes.bool.isRequired,
  featureModalIsOpen: PropTypes.bool.isRequired,
  _toggleReportModal: PropTypes.func.isRequired,
  _toggleFeatureModal: PropTypes.func.isRequired,
  _createTicket: PropTypes.func.isRequired,
  sendingTicket: PropTypes.bool.isRequired,
  loggedUser: PropTypes.shape({}).isRequired,
};

export default Home;
