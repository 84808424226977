/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import Button from 'antd/es/button';
import Icon from 'antd/es/icon';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import get from 'lodash.get';
import moment from 'moment';
import marked from 'marked';

import horseIcon from '../../assets/horse_icon.png';
import eventIcon from '../../assets/events_icon.png';
import travelIcon from '../../assets/travel_icon.png';
import './styles.scss';
import TagBlog from '../TagBlog/TagBlog';

// eslint-disable-next-line no-extend-native
String.prototype.capitalize = () =>
  this.charAt(0).toUpperCase() + this.slice(1);

moment.locale('nb');

const IconText = ({ type, text }) => (
  <span>
    <Icon type={type} style={{ marginRight: 8 }} />
    {text}
  </span>
);

IconText.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const makeEventIcon = (item) => {
  if (item.Type === 'event') {
    return <img src={eventIcon} alt="icon_logo" style={{ width: '46px' }} />;
  }
  if (item.Type === 'travel') {
    return (
      <div className="event-icon" style={{ width: '46px' }}>
        <img src={travelIcon} alt="icon_logo" style={{ width: '75%' }} />
      </div>
    );
  }

  return <img src={horseIcon} alt="icon_logo" style={{ width: '46px' }} />;
};

const statusFor = (item) =>
  item.Status === 'finished' ? (
    <Button
      type="primary"
      size="small"
      disabled
      style={{ backgroundColor: 'green', color: 'white' }}
    >
      Gjennomført
    </Button>
  ) : item.Status === 'cancelled' ? (
    <Button
      type="primary"
      size="small"
      disabled
      style={{ backgroundColor: 'grey', color: 'white' }}
    >
      Avlyst
    </Button>
  ) : (
    <Button
      type="primary"
      size="small"
      href={item.SubscribeLink || '#'}
      target="_blank"
    >
      Påmelding
    </Button>
  );

const ribbonStatus = (item) => (
  item.Status === 'finished' ? (
    <div
      className="ribbon"
      style={{ backgroundColor: 'green', color: 'white' }}
    >
      Gjennomført
    </div>
  ) : item.Status === 'cancelled' ? (
    <div className="ribbon" style={{ backgroundColor: 'grey', color: 'white' }}>
      Avlyst
    </div>
  ) : (
    <div style={{ backgroundColor: '#134c81', color: 'white' }} className="ribbon">Påmelding</div>
  )
);

const imgElem = (item) => {
  const clickable = item.Status !== 'finished' && item.Status !== 'cancelled';
  const img = (
    <img
      width={272}
      alt="logo"
      style={{ objectFit: 'cover', height: '193px', borderRadius: '0.375rem' }}
      src={process.env.REACT_APP_BACKEND_URL + get(item, 'Cover.url', '')}
    />
  );
  const bannerImg = clickable ? (
    <a
      href={item.SubscribeLink || '#'}
      target="_blank"
      rel="noopener noreferrer"
    >
      {img}
    </a>
  ) : (
    img
  );

  return (
    <div className="item-card ribbon-container">
      <div className="ribbon-wrapper">{ribbonStatus(item)}</div>
      {bannerImg}
    </div>
  );

  // eslint-disable-next-line no-unreachable
  return (
    <div className="banner">
      <span className="btn">{statusFor(item)}</span>
      {bannerImg}
    </div>
  );
};

const avatarElem = (item) => (
  <div className="--ant-list-item-meta-avatar" style={{ marginRight: '16px' }}>
    {makeEventIcon(item)}
  </div>
);

const renderEventSectionFor = (events = []) =>
  events.map((event) => (
    <section>
      <ul>
        <li>
          <Row>
            <Col xs={24}>
              <div style={{ display: 'flex' }} className="flex-event-container">
                <div style={{ flex: '1 1' }}>
                  <div style={{ display: 'flex', marginBottom: '16px' }}>
                    {avatarElem(event)}
                    <div
                      style={{
                        color: 'rgba(0, 0, 0, 0.45)',
                        fontSize: '14px',
                        lineHeight: '22px',
                        width: '100%',
                      }}
                    >
                      <div>
                        <IconText
                          type="environment"
                          text={event.Location || 'Nowhere'}
                        />
                      </div>
                      <div>
                        <IconText
                          type="calendar"
                          text={moment(event.Date)
                            .format('dddd DD MMMM, H:mm')
                            .capitalize()}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      color: 'rgba(0,0,0,.65)',
                      fontSize: '14px',
                    }}
                    dangerouslySetInnerHTML={{
                      __html: marked(event.Content || ''),
                    }}
                  />
                  <div className="tag-wrapper-inline">
                    <TagBlog style={{margin: '1%'}} model={event} />
                  </div>
                  {/* <div>{statusFor(event)}</div> */}
                </div>
                <div className="flex-event-image">{imgElem(event)}</div>
              </div>
            </Col>
          </Row>
        </li>
      </ul>
    </section>
  ));

const renderEventsForYear = (datas, year = '2020') => (
  <section className="year">
    <h3>
      <strong>{year}</strong>
    </h3>
    {renderEventSectionFor(datas)}
  </section>
);

const Timeline = ({ events }) => {
  // Revert so first would be most recent
  const yearOrder = Object.keys(events).sort(
    (a, b) => parseInt(b, 10) - parseInt(a, 10)
  );

  return (
    <div id="timeline">
      <div id="timeline-div">
        {yearOrder.map((year) => renderEventsForYear(events[year], year))}
      </div>
    </div>
  );
};

Timeline.defaultProps = {
  events: [],
};

Timeline.propTypes = {
  events: PropTypes.arrayOf(PropTypes.any),
};

export default Timeline;
