import React from 'react';
import Modal from 'antd/es/modal';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DetailsForm from './DetailsForm';
import {
  toggleDeltagereModal,
  logout as reduxLogout,
  toggleUpdatePasswordModal,
} from '../../../redux/modules/App';

const renderLinkWithNoClickEvt = (text, action = null) => (
  <a
    href="!#"
    className="dropdown-menu-link"
    onClick={(e) => {
      e.preventDefault();
      if (typeof action === 'function') {
        action();
      }
    }}
    style={{margin: 20}}
  >
    {text}
  </a>
);

const ModalDetailsForm = ({
  _toggleDeltagereModal,
  loggedUser,
  logout,
  togglePwdModal,
  userModalVisible,
}) => (
  <Modal
    visible={userModalVisible}
    footer={null}
    centered
    className="modal-blog modal-setting"
    destroyOnClose
    onCancel={() => {
      _toggleDeltagereModal();
    }}
  >
    <div className="container layout-width" style={{ paddingTop: 0 }}>
      <div
        className="top-btn-setting"
      >
        {renderLinkWithNoClickEvt('Endre passord', () => {
          togglePwdModal();
        })}
        {renderLinkWithNoClickEvt('Logg ut', () => {
          logout();
        })}
      </div>
      <DetailsForm profile={loggedUser} editable />
    </div>
  </Modal>
);

const mapStateToProps = (state) => ({
  loggedUser: state.App.loggedUserObj,
  userModalVisible: state.App.deltagereModalShown,
});

const mapDispatchToProps = (dispatch) => ({
  _toggleDeltagereModal: () => dispatch(toggleDeltagereModal()),
  logout: () => {
    dispatch(reduxLogout());
  },
  togglePwdModal: () => {
    dispatch(toggleUpdatePasswordModal());
  },
});

ModalDetailsForm.propTypes = {
  _toggleDeltagereModal: PropTypes.func.isRequired,
  userModalVisible: PropTypes.bool.isRequired,
  loggedUser: PropTypes.shape({}).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDetailsForm);
