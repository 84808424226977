import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles';

const LogoFullScreenBlock = ({ logo }) => (
  <div style={styles.container}>
    <img src={logo} alt="Funinvest Logo" style={styles.image} />
  </div>
);

LogoFullScreenBlock.propTypes = {
  logo: PropTypes.string.isRequired,
};

export default LogoFullScreenBlock;
