import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import NotFound404 from '../../404';
import Investeringer from './Investeringer';
import Krypto from './Krypto';
import Annet from './Annet';
import Startups from './Startups';

const RouteAllScreens = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`} component={Investeringer} />
    <Route path={`${match.path}/krypto`} component={Krypto} />
    <Route path={`${match.path}/annet`} component={Annet} />
    <Route path={`${match.path}/startups`} component={Startups} />
    {/* <Route path={`${match.path}/events`} component={Events} />
    <Route path={`${match.path}/investeringer`} component={Investeringer} /> */}

    {/* Default route */}
    <Route component={NotFound404} />
  </Switch>
);

RouteAllScreens.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default withRouter(RouteAllScreens);
