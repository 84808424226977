import React from 'react';
import Button from 'antd/es/button';
import PropTypes from 'prop-types';

const renderButton = (type, link) => {
  let theme = 'outline';
  let name = '';

  switch (type) {
    case 'twitter':
      name = 'twitter';
      break;
    case 'facebook':
      theme = 'filled';
      name = 'facebook';
      break;
    case 'instagram':
      name = 'instagram';
      break;
    case 'linkedin':
      name = 'linkedin';
      break;
    case 'mail':
      name = 'mail';
      break;
    default:
      return null;
  }

  const linkAttrs = !link
    ? { disabled: true }
    : { href: link, target: '_blank' };

  if (type === 'mail') {
    linkAttrs.href = `mailto:${link}`;
  }

  return (
    <Button
      className="social-button"
      type="primary"
      shape="circle"
      icon={name}
      size="large"
      theme={theme}
      {...linkAttrs}
    />
  );
};

const SocialMediaButtons = ({ styles, profile }) => (
  <div
    style={Object.assign(
      {},
      { display: 'flex', 
      // justifyContent: 'space-evenly' 
      },
      styles
    )}
    className="social-btn"
  >
    {renderButton('twitter', profile.Twitter)}
    {renderButton('instagram', profile.Instagram)}
    {renderButton('linkedin', profile.Linkedin)}
    {renderButton('facebook', profile.Facebook)}
    {renderButton('mail', profile.email)}
  </div>
);

SocialMediaButtons.defaultProps = {
  styles: {},
  profile: {},
};

SocialMediaButtons.propTypes = {
  styles: PropTypes.shape({}),
  profile: PropTypes.shape({}),
};

export default SocialMediaButtons;
