/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Card from "antd/es/card";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Radio from "antd/es/radio";

import { forEach } from "lodash";
import sum from 'lodash.sum';
import InvesteringerPieChart from './InvesteringerResumePieChart';
import Pulse from '../PulseLoader';
import { randomColor } from '../../constants/chart';
import ContentModal from '../ContentModal';
import Colors from '../../utils/colors';
import NumberHelper from '../../utils/numbers';
import HistoryChart from './HistoryChart';
import chartUtils from "../../utils/chartUtils";


const SwapButton = ({ value, toggleChart, ...restProps }) => (
  <Radio.Group
    value={value}
    buttonStyle="solid"
    onChange={toggleChart}
    {...restProps}
  >
    <Radio.Button value="all">Investeringer</Radio.Button>
    <Radio.Button value="bySektor">Sektorer</Radio.Button>
    <Radio.Button value="byType">Aktivaklasser</Radio.Button>
  </Radio.Group>
);

const TitleWithChartLegend = ({
  chartView,
  toggleChartView,
  investmentTotal,
  showOnMobile,
}) => {
  const [windowWidth, setWidth] = useState(window.innerWidth);
  const isMobile = windowWidth < 769;
  const shouldShow = (isMobile && showOnMobile) || (!showOnMobile && !isMobile);

  useEffect(() => {
    const setWidthState = () => setWidth(window.innerWidth);
    window.addEventListener("resize", setWidthState);

    return () => {
      window.removeEventListener("resize", setWidthState);
    };
  }, []);

  if (!shouldShow) return null;

  return (
    <Row
      style={{ textAlign: "center", marginBottom: "11px", marginTop: "11px" }}
    >
      <Col sm={24} key="t-2">
        <h1 style={{ marginBottom: 0 }}>Porteføljens verdi</h1>
      </Col>
      <Col sm={24}>
        <h2
          style={{
            fontSize: "2.2rem",
            marginBottom: 0,
            lineHeight: 1,
            paddingBottom: "6px",
          }}
        >
          {NumberHelper.separateWith(investmentTotal, " ")}
        </h2>
      </Col>
      <Col sm={24} key="t-1">
        <SwapButton value={chartView} toggleChart={toggleChartView} />
      </Col>
    </Row>
  );
};

// eslint-disable-next-line no-unused-vars
const TotalSplineCharts = ({ investments, fetch, setKrypto, loading, historyLoading }) => {
  const [opened, setOpened] = useState();
  const [currentElem, setCurrentElem] = useState();
  const [chartView, setChartView] = useState("all");
  const [currentSort, setCurrentSort] = useState({
    asc: false,
    az: false,
    id: "caret-down",
  });

  const { _groupBy, _sortBy } = chartUtils;

  // Handling sort here to allow chart to re-render
  const sortInvestment = (sort) => {
    const sortingIsSet = !!sort.columnKey;
    const options = {
      asc: false,
      az: false,
    };
    if (sortingIsSet) {
      options.asc = sort.order === "ascend";
    }

    return setCurrentSort(options);
  };

  const openModal = (record) => {
    if (chartView === "byType" || chartView === "bySektor") return;
    setCurrentElem(record);
    setOpened(true);
  };

  const toggleChartView = (e) => {
    setChartView(e.target.value);
  };

  useEffect(() => {
    if (investments.length < 1) {
      fetch();
      // fetch krypto once so it gets updated informations
      // if values have changed
      // getKrypto()
      //   .then((kryptos) => {
      //     kryptos.forEach((krypto) => {
      //       setKrypto({
      //         Name: krypto.Name,
      //         Amount: krypto.Amount || 0,
      //       });
      //     });
      //     // for (const [key, value] of Object.entries(kryptos.data || {})) {
      //     //   setKrypto({
      //     //     Name: key,
      //     //     Amount: value || 0,
      //     //   });
      //     // }
      //   })
      //   // eslint-disable-next-line no-console
      //   .catch((e) => console.log(e));
    }

    return () => {};
  }, []);

  let grouppedInvestments = [];
  switch (chartView) {
    case "byType":
      grouppedInvestments = _groupBy(investments, "Type");
      break;
    case "bySektor":
      grouppedInvestments = _groupBy(investments, "sektor");
      break;
    default:
      grouppedInvestments = investments.map((invest) => ({...invest, color: invest.color || randomColor()}));
  }
  const _investments = _sortBy(
    grouppedInvestments,
    currentSort.az ? "normalizedName" : "displayAmount",
    currentSort.asc
  );
  const investmentTotal = Math.round(
    sum(_investments.map((o) => o.displayAmount))
  );
  const investmentsColors =
    _investments.length > 0
      ? _investments.map((investment) => {
          let color = investment.color ? investment.color : randomColor();
          color = color.startsWith("#") ? color : `#${color}`;
          const hover = Colors.shade(color, 10);
          const back = color;

          return { hover, back };
        })
      : [];

  const investUtilMaps = {};
  forEach(investments, (inv) => {
    // investUtilMaps[inv.Name] = { type: inv.Type, sektor: inv.sektor } 
    investUtilMaps[inv.Name] = true;
  });
  
  const investColorMaps = {};
  forEach(_investments, (inv) => {
    investColorMaps[inv.Name] = inv.color.startsWith("#") ? inv.color : `#${inv.color}`;
  });

  return loading || historyLoading ? (
    <Pulse />
  ) : (
    <>
    <Row
      style={{
        paddingBottom: "1.5rem",
        width: "100%",
        margin: "auto",
      }}
      // type="flex"
    >
      <ContentModal
        opened={opened}
        onClose={() => setOpened(false)}
        currentElem={currentElem}
      />
      <Col xl={24} lg={24} md={24} key={2} className="flex-grows">
        <Card
          bordered={false}
          style={{ width: "100%", height: "100%" }}
          className="no-padding-on-mobile investeringer-card-container"
        >
          <TitleWithChartLegend
            chartView={chartView}
            toggleChartView={toggleChartView}
            investmentTotal={investmentTotal}
            showOnMobile
          />
          <InvesteringerPieChart
            labels={
              _investments.length > 0 ? _investments.map((o) => o.Name) : []
            }
            series={
              _investments.length > 0
                ? _investments.map((o) => o.displayAmount)
                : []
            }
            backgroundColor={
              _investments.length > 0
                ? investmentsColors.map((o) => o.back)
                : []
            }
            hoverBackgroundColor={
              _investments.length > 0
                ? investmentsColors.map((o) => o.hover)
                : []
            }
            sortFunc={sortInvestment}
            openModal={openModal}
            shouldUpdateLegend={chartView === "byType"}
            currentSort={currentSort.id}
            investments={_investments}
            investmentsTotal={investmentTotal}
            title={
              <TitleWithChartLegend
                chartView={chartView}
                toggleChartView={toggleChartView}
                investmentTotal={investmentTotal}
                showOnMobile={false}
              />
            }
          />
        </Card>
      </Col>
    </Row>
    <Row style={{paddingBottom: '10%'}}>
      <HistoryChart 
        chartView={chartView} 
        investUtilMaps={investUtilMaps} 
        investColorMaps={investColorMaps}
      />
    </Row>
    </>
  );
};
TotalSplineCharts.propTypes = {
  investments: PropTypes.arrayOf(
    PropTypes.shape({
      Name: PropTypes.string.isRequired,
      Amount: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default TotalSplineCharts;
