import React from 'react';
import Typography from 'antd/es/typography';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Icon from 'antd/es/icon';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import { requestResetToken } from '../../redux/modules/App';

const { Title, Text } = Typography;

const submit = (e, form, action) => {
  e.preventDefault();

  form.validateFields((err, values) => {
    if (!err) {
      action(values.email);
    }
  });
};

const ResettFullScreenBlock = ({ loading, form, _requestToken }) => (
  <div
    style={{
      textAlign: 'center',
      position: 'relative',
      top: '32%',
    }}
    className="login-form-responsive-padding"
  >
    <Title style={{ fontSize: '45px', fontWeight: '300' }}>
      Glemt passord?
    </Title>
    <Text type="secondary" style={{ fontSize: '1.12rem' }}>
      Skriv inn din registrerte epostadresse og vi sender deg instruksjoner for
      å resette ditt passord.
    </Text>
    <br />
    <br />
    <Form
      style={{ margin: 'auto' }}
      onSubmit={(e) => {
        submit(e, form, _requestToken);
      }}
    >
      <Form.Item>
        {form.getFieldDecorator('email', {
          rules: [
            {
              required: true,
              message: 'You must enter an email',
            },
          ],
        })(
          <Input
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Epost"
            size="large"
          />
        )}
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          style={{ width: '100%' }}
          size="large"
          loading={loading}
        >
          SEND
        </Button>
        Har du passord? <Link to="/">Logg inn</Link>
      </Form.Item>
    </Form>
  </div>
);

ResettFullScreenBlock.propTypes = {
  loading: PropTypes.bool.isRequired,
  form: PropTypes.shape({
    validateFields: PropTypes.func,
    getFieldDecorator: PropTypes.func,
  }).isRequired,
  _requestToken: PropTypes.func.isRequired,
};

const mapStateToProps = ({ App }) => ({
  loading: App.isRequestingResetToken,
});

const mapDispatchToProps = (dispatch) => ({
  _requestToken: (email) => {
    dispatch(requestResetToken(email));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'form' })(ResettFullScreenBlock));
