/* eslint-disable class-methods-use-this */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';

const Krypto = () => (
  <div className="iframe-krypto-container">
    <iframe
      src="https://hooks.wau.io/u/fi/index.php?request=gui&noportfolio=true"
      id="krypto-iframe"
    />
  </div>
);

export default Krypto;
