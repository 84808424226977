import EventsModel from '../models/Events';
import { getEvents } from '../../api/Methods';
// import { notifyFailure } from '../../utils/notifications';
import errors from '../../utils/errors';

export const REQUEST_START = 'Events/Request_start';
export const REQUEST_FINISHED = 'Events/Request_finished';

const defaultState = new EventsModel();
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_START:
      return state.toggleLoading();

    case REQUEST_FINISHED:
      return state.toggleLoading().setEvents(action.payload);

    default:
      return state;
  }
};

export default reducer;

export const _fetchEvents = () => (dispatch) => {
  dispatch({ type: REQUEST_START });

  getEvents()
    .then(({ data }) => {
      dispatch({
        type: REQUEST_FINISHED,
        payload: data,
      });
    })
    .catch((err) => errors.handle(err, dispatch));
};
