/* eslint-disable no-console */
import _get from 'lodash.get';

import AppModel from '../models/App';
import {
  authorize,
  updatePassword,
  generateResetToken,
  sendEmail,
  authorizeAccessToken,
} from '../../api/Methods';
import {
  LOGGED_USER_JWT,
  LOGGED_USER_KEY,
  LOGGED_USER_OBJ,
} from '../../utils/StorageKeys';
import {
  notifyLoginFailure,
  notifyUpdatePasswordSuccess,
  notifyLoginSuccess,
  notifyUpdatePasswordFailure,
  notifySuccess,
  notifyFailure,
} from '../../utils/notifications';
import BrowserStorage from '../../utils/browserStorage';
import HttpClientWithAuth from '../../utils/httpClient';

// actions
export const LOGGED_IN = 'app/LOGGED_IN';
export const REQUEST_LOGIN = 'app/REQUEST_LOGIN';
export const LOGIN_SUCCESS = 'app/LOGIN_SUCCESS';
export const LOGIN_FAILED = 'app/LOGIN_FAILED';
export const LOGIN_FB_FAILED = 'app/LOGIN_FB_FAILED';
export const LOGGED_OUT = 'app/LOGGED_OUT';
export const MENU_TOGGLED = 'app/MENU_TOGGLED';
export const DELTAGERE_TOGGLE_MODAL = 'app/DELTAGERE_MODAL_TOGGLED';
export const DELTAGERE_SHOW_USER = 'app/DELTAGERE_SHOW_USER';
export const UPDATE_PWD_TOGGLE_MODAL = 'app/UPDATE_PWD_TOGGLE_MODAL';
export const REQUEST_PASS_UPDATE = 'app/REQUEST_APP_UPDATE';
export const PASS_UPDATE_SUCCESS = 'app/PASS_UP_SUCCESS';
export const PASS_UPDATE_FAILED = 'app/PASS_UP_FAILED';
export const TOGGLE_OM_MODAL = 'app/toggle_om_modal';
export const TOGGLE_REPORT_MODAL = 'app/toggle_report_modal';
export const TOGGLE_FEATURE_MODAL = 'app/toggle_feature_modal';
export const CAPTCHA_CORRECT = 'app/captcha_is_correct';
export const CAPTCHA_INCORRECT = 'app/captcha_is_not_correct';
export const REQUEST_CREATE_TICKET = 'app/request_create_ticket';

export const REQUEST_RESET_TOKEN = 'app/requesting_reset_token';
export const RESET_TOKEN_RECEIVED = 'app/received_reset_token';
export const RESET_TOKEN_ERRORED = 'app/reset_token_errored';

export const REQUEST_TOKEN_VALIDATION = 'app/request_token_validation';
export const REQUEST_TOKEN_VALIDATION_SUCCESS =
  'app/request_token_validation_success';
export const REQUEST_TOKEN_VALIDATION_ERRORED =
  'app/request_token_validation_errored';

const defaultState = new AppModel();

// reducers
const reducer = (state = defaultState, action) => {
  // catch thunks
  if (action === undefined) {
    return state;
  }

  switch (action.type) {
    case REQUEST_LOGIN:
      return state.requestLogin();

    case LOGIN_SUCCESS:
      notifyLoginSuccess();
      BrowserStorage.set(LOGGED_USER_KEY, action.payload.user);
      BrowserStorage.set(LOGGED_USER_JWT, action.payload.jwt);
      BrowserStorage.set(LOGGED_USER_OBJ, action.payload.user_obj, true);

      // set the header to the http client
      // set the jwt token in header
      HttpClientWithAuth.setDefaultHeader(
        'Authorization',
        `Bearer ${action.payload.jwt}`
      );

      return state.requestLoginSuccessed(action.payload);

    case LOGIN_FAILED:
      notifyLoginFailure('Username or password is wrong');
      return state.requestLoginFailed();

    case LOGIN_FB_FAILED:
      notifyLoginFailure(action.payload);
      return state.requestLoginFailed();

    case LOGGED_OUT:
      BrowserStorage.clear();
      return state.logout();

    case MENU_TOGGLED:
      return state.toggleMenu();

    case DELTAGERE_TOGGLE_MODAL:
      return state.toggleDeltagereModal();

    case DELTAGERE_SHOW_USER:
      return state.showUserInDeltagereModal(action.payload);

    case UPDATE_PWD_TOGGLE_MODAL:
      return state.toggleUpdatePasswordModal();

    case REQUEST_PASS_UPDATE:
      return state.requestPasswordUpdate();

    case PASS_UPDATE_SUCCESS:
      return state.requestPasswordUpdateSuccessed();

    case PASS_UPDATE_FAILED:
      return state.requestPasswordUpdateFailed();

    case TOGGLE_OM_MODAL:
      return state.toggleOmModal();

    case CAPTCHA_CORRECT:
      return state.setCaptcha(true);

    case CAPTCHA_INCORRECT:
      return state.setCaptcha(false);

    case TOGGLE_FEATURE_MODAL:
      return state.toggleFeatureModal();

    case TOGGLE_REPORT_MODAL:
      return state.toggleReportModal();

    case REQUEST_CREATE_TICKET:
      return state.toggleCreatingTicket();

    case REQUEST_RESET_TOKEN:
    case RESET_TOKEN_RECEIVED:
      return state.toggleRequestingResetToken();
    case RESET_TOKEN_ERRORED:
      return state.isRequestingResetToken
        ? state.toggleRequestingResetToken()
        : state;

    default:
      return state;
  }
};

// action creators
export const setLoggedInUser = (user) => ({ type: LOGGED_IN, payload: user });

export const loginSuccess = (datas) => ({
  type: LOGIN_SUCCESS,
  payload: { ...datas },
});

export const loginFailed = (reason) => ({
  type: LOGIN_FAILED,
  payload: { reason },
});

export const requestLogin = (username, password, accessToken = null) => (dispatch) => {
  // first dispatch the request login event
  dispatch({ type: REQUEST_LOGIN });

  if(accessToken) {
    authorizeAccessToken(accessToken)
    .then((data) => {
      dispatch(
        loginSuccess({
          user: _get(data, 'data.user.username', ''),
          jwt: _get(data, 'data.jwt', ''),
          user_obj: _get(data, 'data.user', {}),
        })
      );
    })
    .catch((err) => {
      dispatch({
        type: LOGIN_FB_FAILED,
        payload: _get(err, 'data.message.message', 'Unable to login using Facebook')
      });
    })
  } else {
    // compute auth and then dispatch the correct events
    authorize(username, password)
      .then((data) => {
        dispatch(
          loginSuccess({
            user: _get(data, 'data.user.username', ''),
            jwt: _get(data, 'data.jwt', ''),
            user_obj: _get(data, 'data.user', {}),
          })
        );
      })
      .catch((err) => {
        dispatch(
          loginFailed(
            _get(
              err,
              'response.data.message[0].messages[0].message',
              'There was an error when login in'
            )
          )
        );
      });
  }

};

export const requestResetToken = (email) => (dispatch) => {
  // first dispatch the requesting event
  dispatch({ type: REQUEST_RESET_TOKEN });

  generateResetToken(email)
    // eslint-disable-next-line no-unused-vars
    .then(({ data }) => {
      notifySuccess(`An email has been sent to ${email}`);
      dispatch({ type: RESET_TOKEN_RECEIVED });
    })
    .catch((err) => {
      dispatch({ type: RESET_TOKEN_ERRORED });
      notifyFailure(
        'Error',
        `There was an error while generating a token : ${err.message}`
      );
    });
};

export const requestPasswordUpdate = (newPassword, usr = null) => (
  dispatch
) => {
  const loggeduserFromLs = !usr
    ? BrowserStorage.get(LOGGED_USER_OBJ, {})
    : JSON.stringify(usr);
  const user =
    typeof loggeduserFromLs === 'string'
      ? JSON.parse(loggeduserFromLs)
      : loggeduserFromLs;

  if (!user || !user.id) {
    // eslint-disable-next-line no-console
    console.error('USER could not be found in local storage');
    return false;
  }

  // first dispatch the request event for sending loading state to the UI
  dispatch({ type: REQUEST_PASS_UPDATE });

  // then update the password
  return (
    updatePassword(user.id, newPassword)
      // eslint-disable-next-line no-unused-vars
      .then((res) => {
        dispatch({ type: PASS_UPDATE_SUCCESS });
        // when usr is set it means that password update was made from forgot password
        notifyUpdatePasswordSuccess(!usr);
        // Log out after 2 seconds
        if (!usr) {
          setTimeout(() => dispatch({ type: LOGGED_OUT }), 2000);
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
        const error = e.response.data ? e.response.data : e;
        dispatch({ type: PASS_UPDATE_FAILED, payload: error.message });
        notifyUpdatePasswordFailure(error.message);
      })
  );
};

export const toggleMenu = () => ({ type: MENU_TOGGLED });

export const logout = () => ({ type: LOGGED_OUT });

export const toggleDeltagereModal = () => ({ type: DELTAGERE_TOGGLE_MODAL });

export const showUserInDeltagereModal = (index) => ({
  type: DELTAGERE_SHOW_USER,
  payload: index,
});

export const toggleOmModal = () => ({
  type: TOGGLE_OM_MODAL,
});

export const toggleUpdatePasswordModal = () => ({
  type: UPDATE_PWD_TOGGLE_MODAL,
});

export const setCaptchaSuccess = (correct) =>
  correct
    ? {
        type: CAPTCHA_CORRECT,
      }
    : {
        type: CAPTCHA_INCORRECT,
      };

export const toggleReportModal = () => ({
  type: TOGGLE_REPORT_MODAL,
});

export const toggleFeatureModal = () => ({
  type: TOGGLE_FEATURE_MODAL,
});

export const createTicket = ({ ...args }) => (dispatch) => {
  // Put the create ticket request flag on
  dispatch({ type: REQUEST_CREATE_TICKET });
  sendEmail({
    recipient: 'post@funinvest.no',
    subject: `${
      args.type === 'epic' ? 'New Feature Request' : 'Bug reported'
    } - Funinvest Portal`,
    text: `Title:${args.title}, Description:${args.description}`,
    html: `
    <p>
      <strong>Title : </strong>${args.title}
    </p>
    <p>
      <strong>Description : </strong>${args.description}
    </p>`,
  })
    // eslint-disable-next-line no-unused-vars
    .then((data) => {
      // Put the create Ticket request off
      dispatch({ type: REQUEST_CREATE_TICKET });
      // close the moodal
      if (args.type === 'epic') {
        dispatch(toggleFeatureModal());
      } else {
        dispatch(toggleReportModal());
      }
      notifySuccess('Ticket has been sent successfully');
    })
    .catch((e) => {
      notifyFailure('Error on sending ticket');
      console.error(e);
    });

  /**
   * @deprecated
   *
   *
   *  createJiraTicket({ ...args })
    // eslint-disable-next-line no-unused-vars
    .then(({ data }) => {
      // Put the create Ticket request off
      dispatch({ type: REQUEST_CREATE_TICKET });
      // close the moodal
      if (args.type === 'epic') {
        dispatch(toggleFeatureModal());
      } else {
        dispatch(toggleReportModal());
      }
      notifySuccess('Ticket has been sent successfully');
      console.log(args);
    })
    .catch((e) => {
      notifyFailure('Error on sending ticket');
      console.error(e);
    });
   *
   *
   */
};

export default reducer;
