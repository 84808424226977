import { logout } from '../redux/modules/App';
import { notifyFailure } from './notifications';

export default {
  handle: (err, dispatch) => {
    notifyFailure('Error', err.message);
    if (err.message.includes('401')) {
      dispatch(logout());
    }
  },
};
