import update from 'immutability-helper';
import { LOGGED_USER_KEY, LOGGED_USER_OBJ } from '../../utils/StorageKeys';

import BrowserStorage from '../../utils/browserStorage';

export default class AppModel {
  constructor() {
    // boot from browser storage
    this.loggedUser = BrowserStorage.get(LOGGED_USER_KEY);
    this.loggedUserObj = JSON.parse(BrowserStorage.get(LOGGED_USER_OBJ));
    this.loggedIn = this.loggedUser !== '' && this.loggedUser !== null;
    this.isRequestingLogin = null;
    this.loginRequestSuccess = null;
    this.menuFolded = false;
    this.deltagereModalShown = false;
    this.deltagereCurrentUserInModal = 1; // index based user to show in modal
    this.updatePasswordModalShown = false;
    this.isRequestingPasswordUpdate = false;
    this.passwordUpdateRequestSuccess = null;
    this.omModalShown = false;
    this.captchaIsCorrect = false;
    this.reportModalShown = false;
    this.featureModalShown = false;
    this.isCreatingTicket = false;
    this.isRequestingResetToken = false;
    this.isRequestingTokenValidation = false;
  }

  toggleRequestingResetToken() {
    return update(this, {
      isRequestingResetToken: { $set: !this.isRequestingResetToken },
    });
  }

  toggleRequestingTokenValidation() {
    return update(this, {
      isRequestingTokenValidation: { $set: !this.isRequestingTokenValidation },
    });
  }

  toggleCreatingTicket() {
    return update(this, {
      isCreatingTicket: { $set: !this.isCreatingTicket },
    });
  }

  toggleOmModal() {
    return update(this, {
      omModalShown: { $set: !this.omModalShown },
    });
  }

  toggleReportModal() {
    return update(this, {
      reportModalShown: { $set: !this.reportModalShown },
    });
  }

  toggleFeatureModal() {
    return update(this, {
      featureModalShown: { $set: !this.featureModalShown },
    });
  }

  setLoggedUser(username) {
    return update(this, { loggedUser: { $set: username } });
  }

  toggleMenu() {
    return update(this, { menuFolded: { $set: !this.menuFolded } });
  }

  requestLogin() {
    return update(this, {
      isRequestingLogin: { $set: true },
      loginRequestSuccess: { $set: null },
    });
  }

  requestPasswordUpdate() {
    return update(this, {
      isRequestingPasswordUpdate: { $set: true },
      passwordUpdateRequestSuccess: { $set: null },
    });
  }

  // eslint-disable-next-line camelcase
  requestLoginSuccessed({ user, user_obj }) {
    return update(this, {
      loggedUser: { $set: user },
      loggedUserObj: { $set: user_obj },
      loggedIn: { $set: true },
      isRequestingLogin: { $set: false },
      loginRequestSuccess: { $set: true },
    });
  }

  requestLoginFailed() {
    return update(this, {
      loggedUser: { $set: '' },
      loggedIn: { $set: false },
      isRequestingLogin: { $set: false },
      loginRequestSuccess: { $set: false },
    });
  }

  requestPasswordUpdateSuccessed() {
    return update(this, {
      isRequestingPasswordUpdate: { $set: false },
      passwordUpdateRequestSuccess: { $set: true },
      updatePasswordModalShown: { $set: false },
    });
  }

  requestPasswordUpdateFailed() {
    return update(this, {
      isRequestingPasswordUpdate: { $set: false },
      passwordUpdateRequestSuccess: { $set: false },
      updatePasswordModalShown: { $set: true },
    });
  }

  logout() {
    return update(this, {
      loggedUser: { $set: '' },
      loggedIn: { $set: false },
      isRequestingLogin: { $set: null },
      loginRequestSuccess: { $set: null },
    });
  }

  toggleDeltagereModal() {
    return update(this, {
      deltagereModalShown: { $set: !this.deltagereModalShown },
    });
  }

  showUserInDeltagereModal(index) {
    return update(this, {
      deltagereCurrentUserInModal: { $set: index },
    });
  }

  toggleUpdatePasswordModal() {
    return update(this, {
      updatePasswordModalShown: { $set: !this.updatePasswordModalShown },
    });
  }

  setCaptcha(correct) {
    return update(this, {
      captchaIsCorrect: { $set: correct },
    });
  }
}
