import { connect } from 'react-redux';
import React from 'react';

import { fetchSt } from '../../../redux/modules/Investments';

import Annet from '../../../components/Investeringer/Annet';

const MyAnnet = ({ companies, fetch, loading }) => (
  <Annet companies={companies} fetch={fetch} loading={loading} />
);

const mapStateToProps = ({ Investments }) => ({
  companies: Investments.annet,
  loading: Investments.loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: () => dispatch(fetchSt('annet')),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAnnet);
