import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'antd/es/modal';
import Icon from 'antd/es/icon';
import Content from './Content';

const ContentModal = ({
  opened,
  onClose,
  className,
  closeIcon,
  currentElem,
}) => (
  <Modal
    title={
      currentElem && currentElem.Image && currentElem.Image.length > 0 ? (
        <img
          width={125}
          src={`${process.env.REACT_APP_BACKEND_URL}${currentElem.Image[0].url}`}
          alt={currentElem.Name || ''}
        />
      ) : null
    }
    visible={opened}
    footer={null}
    onCancel={onClose}
    style={{ maxWidth: '768px' }}
    centered
    className={className}
    destroyOnClose
    closeIcon={closeIcon}
  >
    <Content currentElem={currentElem} />
  </Modal>
);

ContentModal.defaultProps = {
  opened: false,
  className: 'modal-investors',
  // modalContent: (<div />),
  onClose: () => {},
  closeIcon: <Icon type="close" />,
  currentElem: null,
};

ContentModal.propTypes = {
  opened: PropTypes.bool,
  // modalContent: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.func.isRequired]),
  className: PropTypes.string,
  onClose: PropTypes.func,
  closeIcon: PropTypes.element,
  currentElem: PropTypes.element,
};

export default ContentModal;
