import { createStore, combineReducers, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import allReducers from './modules';

const configureStore = () => {
  const combinedReducers = combineReducers(allReducers);
  const middlewares = [];

  if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger);
  }

  middlewares.push(thunk);
  const store = createStore(combinedReducers, applyMiddleware(...middlewares));

  return store;
};

export default configureStore;
