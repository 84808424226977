import PropTypes from 'prop-types';
import React from 'react';
import Swipe from 'react-easy-swipe';
import _get from 'lodash.get';
import { connect } from 'react-redux';
import find from 'lodash.find';

import ModalNavigation from './ModalNavigation';
import defaultUserImage from '../../assets/user_default.jpeg';
import { goTo } from '../../utils/Navigation';
import {
  showUserInDeltagereModal,
  toggleDeltagereModal,
} from '../../redux/modules/App';
import Details from './EditableProfile/Details';

const mapStateToProps = ({ App, Deltagere }) => ({
  visible: App.deltagereModalShown,
  profiles: Deltagere.profiles,
  userToShow: App.deltagereCurrentUserInModal,
  loggedUser: App.loggedUserObj,
  isEditMode: Deltagere.isEditMode,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModal: () => dispatch(toggleDeltagereModal()),
  setCurrentUser: (id) => dispatch(showUserInDeltagereModal(id)),
});

const DeltagereModal = ({
  profiles,
  userToShow,
  setCurrentUser,
  loggedUser,
  isEditMode,
}) => {
  const profile = find(profiles, (pf) => userToShow === pf.id);
  let userPhoto = _get(profile, 'Photo.url', defaultUserImage);

  if (userPhoto !== defaultUserImage) {
    userPhoto = `${process.env.REACT_APP_BACKEND_URL}${userPhoto}`;
  }

  return (
    <Swipe
      onSwipeLeft={() => {
        if (!isEditMode) {
          goTo('next', userToShow, profiles, setCurrentUser);
        }
      }}
      onSwipeRight={() => {
        if (!isEditMode) {
          goTo('prev', userToShow, profiles, setCurrentUser);
        }
      }}
      tolerance={100}
      className="ant-swipe-modal-body"
    >
      <ModalNavigation
        profiles={profiles}
        userToShow={userToShow}
        setCurrentUser={setCurrentUser}
        show={!isEditMode}
      />
      <Details profile={profile} editable={loggedUser.id === profile.id} />
    </Swipe>
  );
};

DeltagereModal.propTypes = {
  profiles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userToShow: PropTypes.string.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
  loggedUser: PropTypes.shape({}).isRequired,
  isEditMode: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeltagereModal);
