import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Login from './user/Login';
import NotFound404 from './404';
import Om from './om';
import Resett from './resett';
import Credentials from './credentials';
import Home from './home';

const routeAllScreens = () => (
  <Switch>
    <Route exact path="/" component={Login} />
    <Route path="/home" component={Home} />
    <Route path="/share" component={Home} />
    <Route path="/om" component={Om} />
    <Route path="/resett" component={Resett} />
    <Route path="/credentials/reset" component={Credentials} />

    {/* Default route */}
    <Route component={NotFound404} />
  </Switch>
);

export default routeAllScreens;
