/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import Col from 'antd/es/col';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleViewModalAction } from '../../redux/modules/Blog';

import BlogPlaceholder from '../../assets/blog-placeholder.jpg';
import HtmlHelper from '../../utils/html';


const BlogEntryDrawer = ({ art, openModal, _toggleDrawer, setCloseModalSignal }) => {
  const imageUrl = art.feature_image
    ? `${process.env.REACT_APP_BACKEND_URL}${art.feature_image.url}`
    : BlogPlaceholder;

  return (
    <Col
      lg={8}
      md={12}
      sm={24}
      className={art.isFeatured ? 'article-featured' : 'article-not-featured'}
    >
      <article
        key={art.id}
        className="row blog-card-list-v1 mb-4"
        style={{ marginBottom: '5px' }}
      >
        <Col lg={24} sm={24} className="blog-entry-card">
          <a
            className="blog-card__img"
            role="button"
            onClick={() => {
              HtmlHelper.setUrlToBlogPostUrl(art);
              HtmlHelper.setPageTitle(`${art.Title}`);
              setCloseModalSignal();
              setTimeout(() => {
                _toggleDrawer(false);
                openModal();
              }, 200);
            }}
          >
            <div className="overlay-blog-title">
              <h4 className="blog-card__title">{art.Title}</h4>
            </div>
            <div className="overlay-blog-cover" />
            <img src={imageUrl} alt="Blog cover" />
          </a>
        </Col>
      </article>
    </Col>
  );
};

BlogEntryDrawer.propTypes = {
  art: PropTypes.shape({
    link: PropTypes.string,
    Title: PropTypes.string.isRequired,
    Text: PropTypes.string.isRequired,
    Author: PropTypes.shape({}),
  }).isRequired,
  openModal: PropTypes.func.isRequired,
  setCloseModalSignal: PropTypes.func.isRequired,
  _toggleDrawer: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(toggleViewModalAction),
});

export default connect(null, mapDispatchToProps)(BlogEntryDrawer);
