import React from "react";
import membersIcon from "../assets/blog_icon.png";
// import eventsIcon from '../assets/events_icon.png';
import calendarIcon from "../assets/calendar.png";
import horseIcon from "../assets/horse_icon.png";
import fundsIcon from "../assets/funds_icon.png";
import HomeIcon from "../components/HomeIcon";

import annetIcon from "../assets/sub_annet_icon.png";
import kryptoIcon from "../assets/sub_krypto_icon.png";
import startupsIcon from "../assets/sub_startups_icon.png";

const addToInvesteringerBaseUrl = (url) => `/home/investeringer/${url}`;

export const INVESTERINGER_SUB_MENUS = [
  {
    id: 8,
    to: addToInvesteringerBaseUrl("annet"),
    img: annetIcon,
    name: "Andre investeringer",
  },
  /*  {
    id: 9,
    to: addToInvesteringerBaseUrl('fond'),
    img: subFundsIcon,
    name: 'Fond',
  },
  {
    id: 10,
    to: addToInvesteringerBaseUrl('kontanter'),
    img: kontanterIcon,
    name: 'Kontanter',
  }, */
  {
    id: 11,
    to: addToInvesteringerBaseUrl("krypto"),
    img: kryptoIcon,
    name: "Krypto",
  },
  {
    id: 12,
    to: addToInvesteringerBaseUrl("startups"),
    img: startupsIcon,
    name: "Startups",
  },
];

export const menus = [
  {
    id: 1,
    to: "/home",
    name: "Home",
    icon: "home",
    component: <HomeIcon />,
  },
  {
    id: 2,
    to: "/home/deltagere",
    name: "Deltagere",
    icon: "user",
    img: membersIcon,
  },
  {
    id: 3, // DO NOT CHANGE THIS ID OR CHECK menuList() function if you do so
    to: "/home/investeringer",
    name: "Investeringer",
    icon: "rocket",
    img: fundsIcon,
  },
  {
    id: 4,
    to: "/home/events",
    name: "Events",
    icon: "calendar",
    img: calendarIcon,
  },
  {
    id: 5,
    to: "/home/hest",
    name: "Hest",
    icon: "gitlab",
    img: horseIcon,
  },
];
