import React from 'react';
import PropTypes from 'prop-types';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Table from 'antd/es/table';
import Spin from 'antd/es/spin';
import { connect } from 'react-redux';
import find from 'lodash.find';

const buildResultsDatas = (polls, profiles) => {
  // build an array for each choice
  const res = [];

  polls.choices.forEach((choice, index) => {
    if (index === 0) {
      // just push for the first choice
      return polls.results[choice.id].voters.forEach((voter) => {
        res.push([voter]);
      });
    }

    // then for the others
    return polls.results[choice.id].voters.forEach((_voter, _index) => {
      if (res.length > _index) {
        // just add to current index
        res[_index].push(_voter);
      } else {
        // add a new line
        const newLine = new Array(choice.id).fill('');
        newLine.push(_voter);
        res.push(newLine);
      }
    });
  });

  // Return a correct object
  return res.map((val, idx) => ({
    id: idx,
    // for each voter, replace with the real name
    values: val.map((probableUserId) => {
      const realUser = find(
        profiles,
        (profile) => profile.id === probableUserId
      );

      return realUser ? realUser.username : probableUserId;
    }),
  }));
};

const PollsResults = ({ article, isLoading, profiles }) => {
  if (!article.polls || !article.polls.enabled) {
    return null;
  }

  // eslint-disable-next-line no-prototype-builtins
  const anonymousPropertyExists = article.polls.hasOwnProperty('anonymous');
  if (!anonymousPropertyExists) {
    return null;
  }
  const { anonymous } = article.polls;
  if (anonymous) {
    return null;
  }

  const { polls } = article;
  const resultsColumns = !polls.enabled
    ? []
    : polls.choices.map((choice) => ({
        title: choice.label,
        dataIndex: choice.id,
        render: (text, record) => record.values[choice.id],
      }));

  return isLoading ? (
    <Spin
      size="large"
      tip="Loading results..."
      style={{
        margin: 'auto',
        display: 'block',
        paddingTop: '26%',
      }}
    />
  ) : (
    <Row>
      <Col xs={24}>
        <h3
          style={{
            textAlign: 'center',
            fontSize: '1.5rem',
            marginTop: '3rem',
          }}
        >
          Results
        </h3>
      </Col>
      <Col xs={24}>
        <Table
          columns={resultsColumns}
          rowKey={(record) => record.id}
          dataSource={buildResultsDatas(polls, profiles)}
        />
      </Col>
    </Row>
  );
};

PollsResults.defaultProps = {
  article: {},
  profiles: [],
};

PollsResults.propTypes = {
  article: PropTypes.shape({}),
  profiles: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ Deltagere }) => ({
  profiles: Deltagere.profiles,
  isLoading: Deltagere.isLoading,
});

export default connect(mapStateToProps)(PollsResults);
