export default {
  layoutGlobal: {
    height: '100vh',
    minHeight: '-webkit-fill-available',
  },
  sidenav: {
    img: {
      height: '64px',
      display: 'inline-block',
      padding: '10px',
      marginLeft: '7px',
    },
    img2: {
      height: '22px',
      margin: '21px 7px',
      display: 'block',
    },
    img3: {
      height: '22px',
      margin: '21px 2px',
      display: 'block',
    },
  },
  header: {
    background: '#fff',
    padding: 0,
    borderBottom: '1px solid #e8e8e8',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 1200,
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '64px'
  },
};
