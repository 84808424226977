import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import NotFound404 from '../404';
import Blog from './Blog';
import Deltagere from './Deltagere';
import Hest from './Hest';
import Events from './Events';
import Investeringer from './Investeringer';

const RouteAllScreens = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`} component={Blog} />
    <Route path={`${match.path}/blog/post/:id`} component={Blog} />
    <Route path={`${match.path}/deltagere`} component={Deltagere} />
    <Route path={`${match.path}/hest`} component={Hest} />
    <Route path={`${match.path}/events`} component={Events} />
    <Route path={`${match.path}/investeringer`} component={Investeringer} />

    {/* Default route */}
    <Route component={NotFound404} />
  </Switch>
);

RouteAllScreens.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default withRouter(RouteAllScreens);
