import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

function disabledDate(current) {
    // Can not select days before today and today
    return current > moment().endOf('day');
  }


const PeriodFilter = ({onChange, startDate,dateFormat, endDate}) => (
    <RangePicker
    defaultValue={[startDate,endDate]}
    format={dateFormat}
    onOk={onChange}
    // separator="før"
    showTime={{ format: 'HH:mm' }}
    dropdownClassName="chart-filter"
    disabledDate={disabledDate}
  />
)

export default PeriodFilter;