/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import PropTypes from 'prop-types';

import ListWithModal from '../../hoc/ListWithModal';
import Pulse from '../PulseLoader';
import Content from '../ContentModal/Content';

const Annet = ({ companies, fetch, loading }) => {
  useEffect(() => {
    if (companies.length < 1) {
      fetch();
    }
  }, []);

  return loading ? (
    <Pulse />
  ) : (
    <div className="annet-container">
      <Row>
        <ListWithModal
          datas={companies}
          modalContent={(currentElem) => <Content currentElem={currentElem} />}
          render={(company, openModal) => (
            <Col
              sm={8}
              className="investment-col"
              key={`company-${company.Name}`}
            >
              <div className="investment-card-reworked" onClick={openModal}>
                <img
                  src={`${process.env.REACT_APP_BACKEND_URL}${company.Image[0].url}`}
                  alt={company.Name}
                />
              </div>
            </Col>
          )}
        />
      </Row>
    </div>
  );
};

Annet.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Annet;
