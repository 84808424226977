import findKey from 'lodash.findkey';

/* eslint-disable import/prefer-default-export */
export const goTo = (direction, currentUserId, profiles, showUser) => {
  let indexOfCurrentUser = findKey(profiles, (p) => currentUserId === p.id);
  indexOfCurrentUser = parseInt(indexOfCurrentUser, 10);
  if (direction === 'prev') {
    indexOfCurrentUser -= 1;
    if (indexOfCurrentUser < 0) indexOfCurrentUser = profiles.length - 1;
  } else {
    indexOfCurrentUser += 1;
    if (indexOfCurrentUser > profiles.length - 1) indexOfCurrentUser = 0;
  }
  showUser(profiles[indexOfCurrentUser].id);
};
