/* eslint-disable max-len */
/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useEffect, useState } from 'react';
// import Typography from 'antd/es/typography';
// import Card from 'antd/es/card';
import Row from 'antd/es/row';
import Col from 'antd/es/col';

/* import CoverPhoto from '../../assets/Cover.jpg';
import Hest1 from '../../assets/hest1.jpg';
import Hest2 from '../../assets/hest2.jpg';
import Hest3 from '../../assets/hest3.jpg';
import Hest4 from '../../assets/hest4.jpg'; */
import HestBadge from './HestBadge';
import { getHests } from '../../api/Methods';
import PulseLoader from '../PulseLoader';

// const { Paragraph } = Typography;

/* const content = (
  <>
    <div className="bg-hero">
      <div className="bg-hero-title">
        <p>Destroyer</p>
      </div>
      <div className="bg-hero-overlay" />
      <img src={CoverPhoto} alt="Destroyer" />
    </div>

    <div className="heist-content small-container" key={1}>
      <div className="small-container-pager">
        <Card style={{ marginBottom: '1.75rem' }}>
          <Paragraph style={{ marginBottom: 0 }}>
            Alle oppdateringer og løp annonseres av Team Bullern & Malmin i{' '}
            <a href="https://www.facebook.com/groups/funinvest/">
              facebookgruppa
            </a>{' '}
            vår. <br />
            Du kan også se karriere, starter, status og stamtavle hos{' '}
            <a href="http://www.travsport.no/Andre-elementer/Sok-etter-hestkusklop/Sok-etter-hest/?modus=0&search=Destroyer">
              travsport.no
            </a>
          </Paragraph>
        </Card>
        <Paragraph className="blog-paragraph">
          Destroyer kommer fra stallen til Gøran Antonsen og har en gullkantet
          stamtavle. Queen L er Destroyers mormor, og hun var i sin tid verdens
          beste hest med seier i verdens største travløp, Prix D’Amerique.
          Destroyer har også samme far som Lionel, så avstammingen er absolutt
          av beste merke.
        </Paragraph>
        <Paragraph className="blog-paragraph">
          Vi har også vært så heldige at Destroyer bor på en av norges beste
          staller og trenes og kuskes av Trond Martinssen og Kristian Malmin, i
          Team Bullern og Malmin. Alle i Fun er velkomne til å besøke stallen.
        </Paragraph>
        <Paragraph className="blog-paragraph">
          Destroyer har vist seg godt i trening, og har vinter og vår gått gode
          løp med flotte plasseringer. Han blir bare bedre og bedre og både
          trener og kusk er meget godt fornøyd.
        </Paragraph>
        <Paragraph className="blog-paragraph">
          Vi gleder oss til mye moro med Destroyer fremover og satser på at alle
          stiller til løp når det er mulig.
        </Paragraph>

        <img src={Hest1} alt="Hest" />
        <img src={Hest2} alt="Hest" />
        <img src={Hest3} alt="Hest" />
        <img src={Hest4} alt="Hest" />
      </div>
    </div>
  </>
);

const Hest = () => (
  <Row style={{ position: 'absolute', width: '100%', left: 0 }}>
    <Col lg={24} key={1} style={{ position: 'absolute', width: '100%' }}>
      {content}
    </Col>
  </Row>
); */

const Hests = () => {
  const [hests, setHests] = useState([]);
  const [loading, setLoading] = useState(true);

  const chunkArray = (myArray, size) => {
    const results = [];

    while (myArray.length) {
      results.push(myArray.splice(0, size));
    }

    return results;
  };

  useEffect(() => {
    if (hests.length < 1) {
      // eslint-disable-next-line no-inner-declarations
      async function fetch() {
        const datas = await getHests();
        if (datas) {
          setHests(datas);
        }
        setLoading(false);
      }

      fetch();
    }
  }, []);

  if (loading) return <PulseLoader />;

  if (!hests) return null;

  const allHests = hests.map((hest) => (
    <Col
      span={8}
      lg={8}
      md={8}
      sm={12}
      xs={24}
      key={`$.col.${hest.id}`}
      style={{ paddingBottom: 8 }}
    >
      <HestBadge hest={hest} />
    </Col>
  ));

  const chunks = chunkArray(allHests, 3);

  return (
      <Row>
        {chunks.map((chunck, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Row gutter={16} key={`$.row.${i}`}>
            {chunck}
          </Row>
        ))}
      </Row>
  );
};

export default Hests;
