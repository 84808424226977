import React from 'react';
import QueueAnim from 'rc-queue-anim';
import PropTypes from 'prop-types';

import TotalSplineChart from './TotalSplineChart';

const Investeringer = ({
  totalPerYear,
  investments,
  fetch,
  setKrypto,
  loading,
  setInvestments,
  historyLoading,
}) => (
  <QueueAnim duration={2000} className="investeringer-container">
    <TotalSplineChart
      totalPerYear={totalPerYear}
      key={1}
      investments={investments}
      fetch={fetch}
      setKrypto={setKrypto}
      loading={loading}
      setInvestments={setInvestments}
      historyLoading={historyLoading}
    />
  </QueueAnim>
);

Investeringer.propTypes = {
  totalPerYear: PropTypes.shape({}).isRequired,
  investments: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Investeringer;
