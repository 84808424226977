import { connect } from 'react-redux';
import Deltagere from '../../../components/Deltagere';
import {
  loadUsers,
  toggleModalEditMode,
} from '../../../redux/modules/Deltagere';

const mapStateToprops = (state) => ({
  profiles: state.Deltagere.profiles,
  sectorOpts: state.Deltagere.sectorOpts,
  loading: state.Deltagere.isLoading,
  isEditMode: state.Deltagere.isEditMode,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: () => dispatch(loadUsers()),
  redxToggleModalEditMode: () => dispatch(toggleModalEditMode()),
});

export default connect(mapStateToprops, mapDispatchToProps)(Deltagere);
