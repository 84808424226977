import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'antd/es/modal';
import Icon from 'antd/es/icon';

const ListWithModal = (props) => {
  const [opened, setOpened] = useState(false);
  const [currentElem, setCurrentElem] = useState(null);

  const openModal = (elem) => {
    setCurrentElem(elem);
    setOpened(true);
  }

  const closeModal = () => {
    const { onCloseModal } = props;
    onCloseModal();
    setOpened(false);
  }
  
  const { modalContent, className, datas, render, closeIcon, closeModalSignal } = props;

  useEffect(() => {
    if(closeModalSignal) {
      closeModal();
    }
  }, [closeModalSignal]);

  return (
      <div>
        <Modal
          visible={opened}
          footer={null}
          onCancel={closeModal}
          style={{ maxWidth: '768px' }}
          centered
          className={className}
          destroyOnClose
          closeIcon={closeIcon}
        >
          {typeof modalContent === 'function'
            ? modalContent(currentElem)
            : modalContent}
        </Modal>

        {datas.map((elem) =>
          render(
            elem,
            () => {
              openModal(elem);
            },
            closeModal
          )
        )}
      </div>
    );
}

ListWithModal.defaultProps = {
  className: 'modal-investors',
  modalContent: <div />,
  onCloseModal: () => {},
  closeIcon: <Icon type="close" />,
  closeModalSignal: 0,
};

ListWithModal.propTypes = {
  datas: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  modalContent: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.func.isRequired,
  ]),
  closeModalSignal: PropTypes.string,
  className: PropTypes.string,
  render: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func,
  closeIcon: PropTypes.element,
};

export default ListWithModal;
