import { connect } from 'react-redux';
import Events from '../../../components/Events';
import { _fetchEvents } from '../../../redux/modules/Events';

const mapStateToProps = (state) => ({
  events: state.Events.events,
  loading: state.Events.loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: () => dispatch(_fetchEvents()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Events);
