import React, { useState } from 'react';
import Button from 'antd/es/button';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash.get';
import BlogView from '../Blog/BlogView';
import { countMyVote, /* toggleViewModalAction */ } from '../../redux/modules/Blog';
import HtmlHelper from '../../utils/html';

const TagBlog = ({ model, keyBlog, style, _vote }) => {
  const blogs = model[keyBlog] || [];
  const [id, setId] = useState();
  const [urlPath, setUrlPath] = useState();
  
  if (blogs.length === 0) {
    return null;
  }
  
  const openModal = (blog) => {
    // eslint-disable-next-line no-unused-expressions
    const currentPath = `${window.location.pathname}`;
    setUrlPath(currentPath);
    HtmlHelper.setUrlToBlogPostUrl(blog);
    HtmlHelper.setPageTitle(_get(blog, 'Title', ''));
    setId(_get(blog, '_id', undefined));
    
    // if(typeof toggleModal === 'function') {
      // toggleModal();
    // }
    // history.push(`/share/blog/post/${_get(blog, '_id', undefined)}`);
    // openBlogModal(_get(blog, 'id', undefined));
  };

  return (
    <>
      <BlogView id={id} articles={blogs} _vote={_vote} urlPath={urlPath} />
      {blogs.map((blog) => (
            <div style={{...style, marginTop: '18px' }}>
              <Button
                className="btn-truncate"
                type="primary"
                icon="tag"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  openModal(blog);
                }}
              >
                {_get(blog, 'Title', '')}
              </Button>
            </div>
      ))}
    </>
  );
};

TagBlog.defaultProps = {
  keyBlog: 'blogs',
};

TagBlog.propTypes = {
  keyBlog: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  _vote: (articleId, choiceId, voterId) =>
    dispatch(countMyVote(articleId, choiceId, voterId)),
  // toggleModal: dispatch(toggleViewModalAction())  
});

export default connect(null, mapDispatchToProps)(TagBlog);
