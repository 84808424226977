import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import Radio from "antd/es/radio";
import { _fetchHistories } from '../../redux/modules/Investments';
import chartUtils from '../../utils/chartUtils';
import PeriodFilter from './Periodfilter';
import { randomColor } from '../../constants/chart';

const dateFormat = 'DD.MM.YYYY';

const FilterButton = ({ value, toggleChart, ...restProps }) => (
  <Radio.Group
    value={value}
    buttonStyle="solid"
    onChange={toggleChart}
    {...restProps}
  >
    <Radio.Button value="total">Samlet</Radio.Button>
    <Radio.Button value="details">Detaljert</Radio.Button>
  </Radio.Group>
);

const HistoryChart = ({fetch, histories, historyFetched, chartView, investColorMaps}) => {
  const { _buildChartDatas } = chartUtils;
  const [currentHistories, setCurrentHistories] = useState(histories);
  const [view, setView] = useState('total');
  // const startDate = new Date(new Date().getFullYear(), 0, 1);
  const  startDate = new Date(`2018-01-23`);
  // startDate.setDate(startDate.getDate() - (364 * 4));
  const [period, setPeriod] = useState({
    start: moment(startDate, dateFormat),
    end: moment(new Date, dateFormat)
  });

  const [asFilter, setAsFilter] = useState(false);

  const asInvestments = currentHistories.map((his) => ({ 
    ...his, 
    all: his.name, 
    bySektor: his.sektor, 
    byType: his.type 
  }));

  const { dates, series } = _buildChartDatas(asInvestments, chartView, view === 'total');

  useEffect(() => {
    if ((asInvestments || []).length < 1) {
      const { start, end } = period; 
      if(!historyFetched) {
        fetch({start: start.toISOString(), end: end.toISOString() });
      }
    }
  }, []);

  useEffect(() => {
    const { start, end } = period; 
    if(asFilter) {
      // fetch({start: start.toISOString(), end: end.toISOString() });
      const filtered = currentHistories.map((hist) => ({...hist, createdAt: moment(hist.createdAt)})).filter((h) => h.createdAt >= start && h.createdAt <= end);
      setCurrentHistories(filtered);
      setAsFilter(false);
    }
  }, [asFilter]);

  const handlePeriodChange = (values) => {
    
    setPeriod({
      start: values[0],
      end: values[1]
    });
    setAsFilter(true);
  }

  const handleViewChange = (v) => {
    setView(v.target.value)
  }

  if((asInvestments || []).length === 0 ) {
    return null;
  }

  const colors = series.map((serie) => investColorMaps[serie.name] || randomColor());

  const options = {
    chart: {
      height: 350,
      type: 'area',
      stacked: true,
      animations: {
        enabled: false
      },
      zoom: {
        type: 'xy',
        autoScaleYaxis: true
      },
      brush: {
        // enabled: true,
        // target: "chart",
        autoScaleYaxis: true
      }
    },
    markers: {
      size: 0
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
      // curve: 'smooth',
    },
    xaxis: {
      type: 'datetime',
      categories: dates,
    },
    yaxis: {
      decimalsInFloat: 0
    },
    tooltip: {
      x: {
        show: true,
        format: 'dd.MM.yyyy',
        formatter (y) {
          if(typeof y !== "undefined") {
            return  moment(y).format("DD/MM/YYYY");
          }
          return y;
          
        }
      },
    },
    noData: {  
      text: "Loading...",  
      align: 'center',  
      verticalAlign: 'middle',  
      offsetX: 0,  
      offsetY: 0,  
      style: {  
        color: "#000000",  
        fontSize: '14px',  
        fontFamily: "Helvetica"  
      }  
    },
  };

  if(view !== 'total')  {
    options.colors = colors;
  } else {
    options.colors = ['#28629B'];
  }

  /* options.tooltip = {
    ...options.tooltip,
    y: {
      formatter: undefined
    }
  }

  if(view === 'total') {
    options.tooltip = { ...options.tooltip,  y: {
      formatter (y) {
        if(typeof y !== "undefined") {
          return  `${y.toFixed(2)  } NOK`;
        }
        return y;
        
      }
    }}
  } */

  return (
    <div>
      <div style={{textAlign: 'center', paddingBottom: '1%'}}>
        <h1 style={{fontSize: 18}}>Porteføljens utvikling</h1>
      </div>
      <div className="chart-filter-wrapper">
        <PeriodFilter 
        onChange={handlePeriodChange}
        startDate={period.start}
        endDate={period.end}
        dateFormat={dateFormat}
        />
          <FilterButton value={view} toggleChart={handleViewChange} style={{marginLeft: '2%'}}/>
        {/* <div className="chart-filter-btn">
        </div> */}
      </div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={550}
        />
      </div>
    </div>
  );
};

  const mapStateToProps = (state) => ({
    histories: state.Investments.histories,
    historyLoading: state.Investments.historyLoading,
    historyFetched: state.Investments.historyFetched,
  });
  
  const mapDispatchToProps = (dispatch) => ({
    fetch: (filter) => dispatch(_fetchHistories(filter)),
  });
  
export default connect(mapStateToProps, mapDispatchToProps)(HistoryChart);