import React from 'react';
import { connect } from 'react-redux';
import Modal from 'antd/es/modal';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Typography from 'antd/es/typography';
import Icon from 'antd/es/icon';
import PropTypes from 'prop-types';

import {
  toggleUpdatePasswordModal,
  requestPasswordUpdate,
} from '../../redux/modules/App';

const { Title, Paragraph } = Typography;

const UpdatePasswordForm = ({ _onSubmit, form, isLoading }) => (
  <Form
    onSubmit={(e) => {
      e.preventDefault();
      _onSubmit(form);
    }}
    style={{ paddingTop: '1.5em' }}
  >
    <Typography>
      <Title>Endre passord</Title>
      <Paragraph
        style={{
          backgroundColor: 'rgb(255, 139, 68, 0.52)',
          padding: '0.5em 1em',
          borderRadius: '4px',
        }}
      >
        <Icon type="warning" /> Når du har oppdatert ditt passord, blir du
        automatisk logget ut for at endringene lagres, du må da logge inn igjen
        med ditt nye passord.
      </Paragraph>
    </Typography>
    <Form.Item>
      {form.getFieldDecorator('password', {
        rules: [{ required: true, message: 'Password is required' }],
      })(<Input type="password" placeholder="New password" />)}
    </Form.Item>
    <Button
      type="primary"
      htmlType="submit"
      className="login-form-button"
      loading={isLoading}
    >
      Endre passord
    </Button>
  </Form>
);

UpdatePasswordForm.propTypes = {
  _onSubmit: PropTypes.func.isRequired,
  form: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const WrappedUpdatePasswordForm = Form.create({ name: 'update_password' })(
  UpdatePasswordForm
);

const UpdatePasswordModal = ({
  modalVisible,
  toggleModal,
  updatePassword,
  isLoading,
}) => (
  <Modal
    visible={modalVisible}
    footer={null}
    onCancel={toggleModal}
    style={{ maxWidth: '768px' }}
    centered
    className="modal-investors"
  >
    <WrappedUpdatePasswordForm
      isLoading={isLoading}
      _onSubmit={(form) => {
        form.validateFields((err, values) => {
          if (!err) {
            updatePassword(values.password);
          }
        });
      }}
    />
  </Modal>
);

UpdatePasswordModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ App }) => ({
  modalVisible: App.updatePasswordModalShown,
  isLoading: App.isRequestingPasswordUpdate,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModal: () => dispatch(toggleUpdatePasswordModal()),
  updatePassword: (newPassword) => dispatch(requestPasswordUpdate(newPassword)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdatePasswordModal);
