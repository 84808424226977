const Styles = {
  blocs: {
    height: '100%',
  },
  row: {
    height: '100vh',
  },
};

export default Styles;
