/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import moment from 'moment';
import Col from 'antd/es/col';
import Button from 'antd/es/button';
import Modal from 'antd/es/modal';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { openModal as rdxOpenModal, loadBlogs } from '../../redux/modules/Blog';
import browserStorage from '../../utils/browserStorage';
import { LOGGED_USER_OBJ } from '../../utils/StorageKeys';

import BlogPlaceholder from '../../assets/blog-placeholder.jpg';
import { deleteBlog } from '../../api/Methods';
import HtmlHelper from '../../utils/html';

const { confirm } = Modal;

const showConfirmDeleteModal = (deletionPromise) =>
  confirm({
    title: 'Are you sure you want to delete this ?',
    content: 'This action is not reversible',
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk: () => deletionPromise(),
  });

const renderEditButton = (art, currentUser, func) => {
  if (!art.Author || art.Author.id !== currentUser.id) {
    return null;
  }

  return (
    <Button
      icon="edit"
      type="primary"
      className="blog-card__edit_button"
      style={{
        position: 'absolute',
        right: '55px',
        top: '5px',
        zIndex: '99999',
      }}
      onClick={func}
    />
  );
};

const renderDeleteButton = (art, currentUser, func) => {
  if (!art.Author || art.Author.id !== currentUser.id) {
    return null;
  }

  return (
    <Button
      icon="close"
      type="danger"
      className="blog-card__edit_button"
      style={{
        position: 'absolute',
        right: '10px',
        top: '5px',
        zIndex: '99999',
        backgroundColor: '#f00',
        color: '#fff',
      }}
      onClick={func}
    />
  );
};

const BlogEntry = ({ art, openModal, _openEditModal, _listBlogs }) => {
  const currentUser = browserStorage.get(LOGGED_USER_OBJ, true);
  const imageUrl = art.feature_image
    ? `${process.env.REACT_APP_BACKEND_URL}${art.feature_image.url}`
    : BlogPlaceholder;

  const deletionPromise = () =>
    new Promise((resolve, reject) => {
      deleteBlog(art.id)
        .then((response) => {
          // refetch the blogs
          _listBlogs();
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  
    const dateFormat = 'DD MMMM[,] YYYY';

  return (
    <Col
      lg={art.isFeatured ? 24 : 8}
      md={art.isFeatured ? 24 : 12}
      sm={24}
      className={art.isFeatured ? 'article-featured' : 'article-not-featured'}
      // style={{paddingLeft: 15, paddingRight: 4}}
    >
      <article
        key={art.id}
        className="row blog-card-list-v1 mb-4"
        style={{ marginBottom: '5px' }}
      >
        {renderEditButton(art, currentUser, () => _openEditModal(art.id))}
        {renderDeleteButton(art, currentUser, () =>
          showConfirmDeleteModal(deletionPromise)
        )}
        <Col lg={24} sm={24} className="blog-entry-card">
          <a
            className="blog-card__img"
            role="button"
            onClick={() => {
              HtmlHelper.setUrlToBlogPostUrl(art);
              HtmlHelper.setPageTitle(`${art.Title}`);
              openModal();
            }}
          >
            <div className="overlay-blog-title">
              <h4 className="blog-card__title">{art.Title}</h4>
              <h5 className="blog-cart__author">
                {art?.Author?.username || ''}
                {art.createdAt ? ` - ${moment(art.createdAt).format(dateFormat)}` : ''}
              </h5>
            </div>
            <div className="overlay-blog-cover" />
            <img src={imageUrl} alt="Blog cover" />
          </a>
        </Col>
      </article>
    </Col>
  );
};

BlogEntry.propTypes = {
  art: PropTypes.shape({
    link: PropTypes.string,
    Title: PropTypes.string.isRequired,
    Text: PropTypes.string.isRequired,
    Author: PropTypes.shape({}),
  }).isRequired,
  openModal: PropTypes.func.isRequired,
  _openEditModal: PropTypes.func.isRequired,
  _listBlogs: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  _openEditModal: (id) => dispatch(rdxOpenModal(id)),
  _listBlogs: () => dispatch(loadBlogs()),
});

export default connect(null, mapDispatchToProps)(BlogEntry);
