import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const OnlyAuthorizedPage = (Component) => {
  const mapStateToProps = (state) => ({
    userIsLogged: state.App.loggedIn,
  });

  const RedirectionComponent = ({ userIsLogged }) => {
    if (userIsLogged !== null && userIsLogged) {
      return <Component />;
    }
    return <Redirect to="/" />;
  };

  RedirectionComponent.propTypes = {
    userIsLogged: PropTypes.bool.isRequired,
  };

  return connect(mapStateToProps)(RedirectionComponent);
};

export default OnlyAuthorizedPage;
