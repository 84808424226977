import update from 'immutability-helper';
import {
  BLOG_POST_TYPE,
  EVENT_TYPE,
  UNREAD_STATUS,
} from '../../constants/notifications';

export default class Notifications {
  constructor() {
    this.entries = [
      {
        id: '1',
        type: BLOG_POST_TYPE,
        created_at: '2019-10-29 15:55:55',
        status: UNREAD_STATUS,
      },
      {
        id: '2',
        type: EVENT_TYPE,
        created_at: '2019-10-29 15:55:55',
        status: UNREAD_STATUS,
      },
      {
        id: '3',
        type: EVENT_TYPE,
        created_at: '2019-10-29 15:55:55',
        status: UNREAD_STATUS,
      },
      {
        id: '4',
        type: BLOG_POST_TYPE,
        created_at: '2019-10-29 15:55:55',
        status: UNREAD_STATUS,
      },
    ];
  }

  push(notification) {
    return update(this, {
      entries: { $push: [notification] },
    });
  }

  remove(index) {
    return update(this, {
      entries: { $splice: [[index, 1]] },
    });
  }
}
