/* eslint-disable no-unused-vars */
import { sortBy, reverse, set, get } from 'lodash';

const isStock = (investment) => investment.Type === 'Noterte_aksjer';

const getAmountKey = (investment) => 'Amount';
// eslint-disable-next-line array-callback-return
const addDisplayAndNormalized = (investments) => {
  if (!investments || !Array.isArray(investments)) return false;
  const getInvestmentValue = (investment) =>
    get(investment, getAmountKey(investment), 0);
  // eslint-disable-next-line array-callback-return
  investments.map((investment, index) => {
    set(investments, [index, 'displayAmount'], getInvestmentValue(investment));
    set(investments, [index, 'normalizedName'], investment.Name.toLowerCase());
  });

  return true;
};

const sortInvestmentsByAmount = (
  investments,
  asc = false,
  key = 'displayAmount'
) => {
  const ascSorted = sortBy(investments, key);
  // eslint-disable-next-line no-param-reassign
  return asc ? ascSorted : reverse(ascSorted);
};

export default {
  isStock,
  getAmountKey,
  sortInvestmentsByAmount,
  addDisplayAndNormalized,
};
