/* eslint-disable no-console */
import React, { useState } from 'react';
import Modal from 'antd/es/modal';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Radio from 'antd/es/radio';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';

import { verifyCapctha, sendEmail } from '../../api/Methods';
import { notifySuccess, notifyFailure } from '../../utils/notifications';

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

// const { TextArea } = Input;

const saveForm = (form, capctchaIsCorrect, closeModal, resetCapctha) =>
  form.validateFields(
    (err, values) => {
      if (!err && capctchaIsCorrect) {
        console.log(values);
        const { name, email, phone, invited, invitedBy } = values;
        sendEmail({
          recipient: 'post@funinvest.no',
          subject: 'Lead for ny aksjonær - Ta kontakt',
          text: `Navn:${name}, Epost:${email}, Telefon:${phone}`,
          html: `
      <p>
        <strong>Navn : </strong>${name}
      </p>
      <p>
        <strong>Epost : </strong>${email}
      </p>
      <p>
        <strong>Telefon : </strong>${phone}
      </p>
      <p>
        <strong>Er du invitert? </strong>${invited ? 'Ja' : 'Nei'}
      </p>
      ${invited ? `<p><strong>Invitert av : </strong>${invitedBy}</p>` : ''}
      `,
        })
          // eslint-disable-next-line no-unused-vars
          .then(({ data }) => {
            notifySuccess(
              'Takk for din interesse. En av styrets medlemmer vil snart ta kontakt med deg.'
            );
          })
          .catch((err2) => {
            notifyFailure('Feil ved sending av melding', err2.message);
          });
        resetCapctha();
        closeModal();
      }
    },
    { force: true }
  );

const OmModal = ({ shown, close, form, captchaIsCorrect, captchaSuccess }) => {
  const [invite, setInvite] = useState(false);

  return (
    <Modal
      title="Ønsker du å bli med i Fun Invest? "
      visible={shown}
      footer={null}
      onCancel={close}
      destroyOnClose
    >
      <h4 style={{ padding: '0 1em' }}>
        Fyll ut skjemaet og vi vil ta kontakt med deg.
      </h4>
      <div style={{ padding: '1em' }}>
        <Form
          onSubmit={(e) => {
            e.preventDefault();

            saveForm(form, captchaIsCorrect, close, captchaSuccess);
          }}
        >
          <Form.Item>
            {form.getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: 'Name is required',
                },
              ],
            })(<Input type="text" placeholder="Navn" />)}
          </Form.Item>

          <Form.Item>
            {form.getFieldDecorator('email', {
              rules: [
                {
                  required: true,
                  message: 'Email is mandatory',
                },
              ],
            })(<Input type="text" placeholder="Epost" />)}
          </Form.Item>
          <Form.Item>
            {form.getFieldDecorator('phone', {
              rules: [
                {
                  required: true,
                  message: 'Phone is required',
                },
              ],
            })(<Input type="text" placeholder="Telefon" />)}
          </Form.Item>
          {/* <Form.Item>
          {form.getFieldDecorator('about', {
            rules: [
              {
                required: true,
                message: 'Field is required',
              },
            ],
          })(<TextArea placeholder="Hvem har tipset deg om Fun?" />)}
        </Form.Item> */}
          <Form.Item {...formItemLayout} label="Er du invitert?" className="om">
            {form.getFieldDecorator(
              'invited',
              {}
            )(
              <Radio.Group onChange={(e) => setInvite(e.target.value)}>
                <Radio value>Ja</Radio>
                <Radio value={false}>Nei</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          {invite ? (
            <Form.Item>
              {form.getFieldDecorator('invitedBy', {
                rules: [
                  {
                    required: true,
                    message: 'Field is required',
                  },
                ],
              })(<Input type="text" placeholder="Invitert av" />)}
            </Form.Item>
          ) : null}
          <Form.Item>
            <ReCAPTCHA
              sitekey="6LfybMYUAAAAABhQB1G8Gv17J5Qhyb6Tqvvteqra"
              onChange={(value) => {
                verifyCapctha(value)
                  .then(({ data }) => {
                    console.log(data);
                    captchaSuccess(true);
                  })
                  .catch((e) => console.error(e.message));
              }}
              onExpired={() => {
                captchaSuccess(false);
              }}
            />
          </Form.Item>

          <Form.Item>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ width: '100%' }}
              disabled={!captchaIsCorrect}
            >
              Send
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
OmModal.propTypes = {
  shown: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  form: PropTypes.shape({}).isRequired,
  captchaIsCorrect: PropTypes.bool.isRequired,
  captchaSuccess: PropTypes.func.isRequired,
};

export default Form.create({ name: 'omForm' })(OmModal);
