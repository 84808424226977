import React from 'react';
import { connect } from 'react-redux';
import Row from 'antd/es/row';
import Icon from 'antd/es/icon';
import Col from 'antd/es/col';
import Divider from 'antd/es/divider';
import List from 'antd/es/list';
import Button from 'antd/es/button';
import _get from 'lodash.get';
import remove from 'lodash.remove';
import marked from 'marked';
import PropTypes from 'prop-types';

import SocialMediaButtons from '../SocialMediaButtons';
import defaultUserImage from '../../../assets/user_default.jpeg';
import DetailsForm from './DetailsForm';
import { toggleModalEditMode } from '../../../redux/modules/Deltagere';
import TagBlog from '../../TagBlog/TagBlog';

class Details extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
    };

    this.toggleEditMode = this.toggleEditMode.bind(this);
  }

  toggleEditMode() {
    const { editMode } = this.state;
    const { redxToggleEdit } = this.props;
    redxToggleEdit();
    this.setState({ editMode: !editMode });
  }

  render() {
    const { profile, editable } = this.props;
    const { editMode } = this.state;

    const renderPhoneNumber = () =>
      profile.Phone ? (
        <h5>
          <Icon type="phone" /> {profile.Phone}
        </h5>
      ) : null;

    const description = _get(profile, 'Desc', '');

    let userPhoto = _get(profile, 'Photo.url', defaultUserImage);

    if (userPhoto !== defaultUserImage) {
      userPhoto = `${process.env.REACT_APP_BACKEND_URL}${userPhoto}`;
    }

    let websites = _get(profile, 'Links', '');
    websites = remove(
      websites.split(';').map((val) => val.trim()),
      (link) => !(link === '' || link === null)
    );

    const editButtonStyle = {
      width: '70px',
      position: 'absolute',
      top: '16px',
      right: '65px',
      zIndex: '999999',
    };

    const renderEditButton = () =>
      editable ? (
        <Button
          size="small"
          type="primary"
          style={editButtonStyle}
          onClick={this.toggleEditMode}
        >
          Rediger
        </Button>
      ) : null;

    return !editMode ? (
      <React.Fragment>
        {renderEditButton()}
        <Row id={0}>
          <Col lg={8} md={8} sm={8} xs={24}>
            <div
              className="avatar avatar-spec"
              style={{
                backgroundImage: `url(${userPhoto})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                borderRadius: '128px',
                width: '128px',
                height: '128px',
                margin: 'auto',
              }}
            />
          </Col>
          <Col
            lg={16}
            md={16}
            sm={16}
            xs={24}
            className="text-centered-on-mobile"
          >
            <h4 style={{ fontSize: '1.25rem', fontWeight: 500 }}>
              {profile.username}
            </h4>
            {renderPhoneNumber()}
            <SocialMediaButtons
              styles={{ marginLeft: '-13px', marginTop: '1rem' }}
              profile={profile}
            />
          </Col>
        </Row>
        <Divider id={1} />
        <Row id={2} style={{ marginTop: '1rem' }} className="modal-content">
          <Col
            lg={12}
            md={24}
            style={{ paddingRight: '1rem', textAlign: 'left' }}
          >
            <span dangerouslySetInnerHTML={{ __html: marked(description) }} />
          </Col>
          <Col lg={12} md={24}>
            <List
              size="small"
              bordered
              dataSource={websites || []}
              renderItem={(link) => (
                <List.Item>
                  <a href={link}>{link}</a>
                </List.Item>
              )}
            />
          </Col>
        </Row>
        <div className="tag-wrapper-inline">
          <TagBlog style={{ margin: '1%' }} model={profile} />
        </div>
      </React.Fragment>
    ) : (
      <div className="container layout-width" style={{ paddingTop: 0 }}>
        <DetailsForm profile={profile} toggleModal={this.toggleEditMode} />
      </div>
    );
  }
}

Details.defaultProps = {
  editable: false,
};

Details.propTypes = {
  profile: PropTypes.shape({}).isRequired,
  editable: PropTypes.bool,
  redxToggleEdit: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  redxToggleEdit: () => dispatch(toggleModalEditMode()),
});

export default connect(null, mapDispatchToProps)(Details);
