import React from "react";
import Icon from "antd/es/icon";
import "./styles.css";

const HomeIcon = () => (
  <div className="home-icon">
    <Icon type="pic-right" theme="outlined" />
  </div>
);

export default HomeIcon;
