import React from 'react';
import { connect } from 'react-redux';
import Form from 'antd/es/form';
import Button from 'antd/es/button';
import Typography from 'antd/es/typography';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toggleOmModal, setCaptchaSuccess } from '../../redux/modules/App';
import OmModal from './OmModal';

const { Text } = Typography;

const OmFullScreenBlock = ({
  toggleModal,
  modalShown,
  captchaIsCorrect,
  _setCaptchaSuccess,
}) => (
  <div
    style={{
      textAlign: 'center',
      position: 'relative',
      top: '19%',
    }}
    className="login-form-responsive-padding"
  >
    <OmModal
      shown={modalShown}
      close={toggleModal}
      captchaIsCorrect={captchaIsCorrect}
      captchaSuccess={_setCaptchaSuccess}
    />
    <Form style={{ margin: 'auto' }}>
      <div
        style={{
          textAlign: 'left',
          fontSize: '1rem',
          lineHeight: '2.1em',
          marginBottom: '2rem',
        }}
      >
        <Text type="secondary">
          <strong>Fun Invest AS</strong> er et nettverk og investeringsselskap
          skapt av og for gründere. Alle aksjonærer er invitert på bakgrunn av
          personlige og profesjonelle egenskaper.
          <br />
          <br />
        </Text>
        <Text type="secondary">
          Som aksjonær i Fun Invest AS får man
          <br />
          <ul>
            <li>
              Et unikt nettverk som jevnlig møtes på uformelle events for mye
              moro
            </li>
            <li>
              En spennende investeringsportefølje som investerer i nyskapende og
              innovative prosjekter.
            </li>
          </ul>
        </Text>
        <Text type="secondary">
          Vårt vedtektsfestede formål er:
          <br />
          <br />
          <em>
            <strong>
              <q>
                Investeringer i finansielle instrumenter, valuta, aksjer,
                eiendom, formuesobjekter samt andre investeringer og aktiviteter
                til fordel for aksjonærene.
              </q>
            </strong>
          </em>
          <br />
          <br />
        </Text>
        <Text type="secondary">
          Vi gjennomfører regelmessig rettede emisjoner.
        </Text>
      </div>
      <Form.Item>
        <Button
          size="large"
          type="primary"
          className="login-form-button"
          style={{ width: '100%' }}
          onClick={toggleModal}
        >
          Jeg vil vite mer
        </Button>
        Er du aksjonær? <Link to="/">Logg inn</Link>
      </Form.Item>
    </Form>
  </div>
);

OmFullScreenBlock.defaultProps = {
  modalShown: false,
  captchaIsCorrect: false,
};

OmFullScreenBlock.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  modalShown: PropTypes.bool,
  captchaIsCorrect: PropTypes.bool,
  _setCaptchaSuccess: PropTypes.func.isRequired,
};

const mapStateToProps = ({ App }) => ({
  modalShown: App.omModalShown,
  captchaIsCorrect: App.captchaIsCorrect,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModal: () => dispatch(toggleOmModal()),
  _setCaptchaSuccess: (correct) => dispatch(setCaptchaSuccess(correct)),
});

const TheForm = Form.create({ name: 'om' })(OmFullScreenBlock);
export default connect(mapStateToProps, mapDispatchToProps)(TheForm);
